import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { CommentFragment } from "graphql/generated/generated";
import { Avatar, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { Close, Reply } from "@material-ui/icons";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import CreateComment from "components/comments/CreateComment";
import { DeleteComment } from "components/comments/DeleteComment";
import { DocumentNode } from "graphql";

const useStyles = makeStyles({
  comment: {
    marginBottom: (props: Props) => {
      return props.isReply ? 0 : 20;
    },
  },

  hasReplies: {
    borderBottom: "1px dashed #eee",
    marginBottom: 10,
  },

  reply: {
    paddingLeft: 50,
    marginTop: 10,

    "& .MuiPaper-root": {
      padding: "5px",
    },
    "& .MuiPaper-outlined": {
      border: "none",
    },
  },

  paper: {
    padding: 20,
  },
});

interface Props {
  isReply?: boolean;
  comment: CommentFragment;
  queryDocument: DocumentNode;
}

const Comment = (props: Props) => {
  const { comment, isReply } = props;
  const [showReply, setShowReply] = useState<number | null>(null);
  const classes = useStyles(props);
  const authCtx = useAuthContext();

  const replies = comment.replies?.nodes || [];

  const toggleReply = (id: number | null) => {
    setShowReply(id);
  };

  const authorName = comment?.commentOptions?.author;

  return (
    <div className={classNames([classes.comment, isReply && classes.reply])}>
      <Paper
        className={classes.paper}
        variant={isReply ? "outlined" : "elevation"}
      >
        <Grid
          container
          wrap="nowrap"
          spacing={2}
          className={classNames(
            !isReply && replies.length > 0 && classes.hasReplies
          )}
        >
          <Grid item>
            <Avatar alt="Remy Sharp" src="" />
          </Grid>

          <Grid item xs>
            <Typography variant="h6">{authorName}</Typography>
            <Typography style={{ textAlign: "left", marginTop: 10 }}>
              <span
                dangerouslySetInnerHTML={{ __html: comment.content || "" }}
              />
            </Typography>
            {comment.date && (
              <Typography
                style={{ textAlign: "left", color: "gray", marginTop: 5 }}
              >
                <span>
                  {format(new Date(comment.date), "dd/MM/yyyy hh:mm a")}
                </span>
              </Typography>
            )}
          </Grid>

          <Grid item>
            {!isReply && (
              <IconButton
                color="default"
                onClick={() =>
                  toggleReply(!showReply ? comment.databaseId : null)
                }
              >
                {showReply === comment.databaseId ? <Close /> : <Reply />}
              </IconButton>
            )}

            {authCtx?.user?.databaseId === comment?.author?.node?.databaseId &&
              comment?.commentedOn?.node?.slug && (
                <DeleteComment
                  id={comment.id}
                  slug={comment?.commentedOn?.node?.slug}
                  queryDocument={props.queryDocument}
                />
              )}
          </Grid>
        </Grid>

        {replies.map((x, i) => (
          <Comment
            key={`${x}-${i}`}
            isReply
            comment={x as CommentFragment}
            queryDocument={props.queryDocument}
          />
        ))}

        <div>
          {showReply === comment.databaseId &&
            comment.commentedOn?.node?.slug &&
            comment.commentedOn.node.databaseId && (
              <CreateComment
                queryDocument={props.queryDocument}
                slug={comment.commentedOn.node.slug}
                commentOn={comment.commentedOn.node.databaseId}
                parent={comment.databaseId}
                onSuccess={() => setShowReply(null)}
                onCancel={() => setShowReply(null)}
              />
            )}
        </div>
      </Paper>
    </div>
  );
};
export default Comment;
