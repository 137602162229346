import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Description, GetApp } from "@material-ui/icons";
import { MediaItem } from "graphql/generated/generated";
import Button from "components/shared/Button/Button";
import Image from "components/shared/Image/Image";

const useStyles = makeStyles({
  courseFiles: {
    marginTop: 30,
  },
  courseFilesTitle: {
    borderBottom: "1px solid #000",
  },
  courseFilesList: {
    padding: 0,
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& a": {
      width: "100%",
      color: "inherit",
    },
  },
  courseFile: {
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    backgroundColor: "#eee",
    borderRadius: 5,
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eeebeb",
    },
  },
  courseFileImage: {},
  courseFileTitle: {
    margin: "0 20px",
    flex: 1,
  },
});

interface Props {
  files: MediaItem[];
  title?: string;
}

export const CourseFiles = (props: Props) => {
  const classes = useStyles();

  if (!props.files || props.files.length === 0) {
    return null;
  }

  return (
    <div className={classes.courseFiles}>
      <Typography variant="h6" className={classes.courseFilesTitle}>
        <strong>{props.title}</strong>
      </Typography>

      <ul className={classes.courseFilesList}>
        {props.files.map((f) => {
          if (!f) return null;

          const isImage = f.mimeType?.includes("image");
          return (
            <a
              key={f.databaseId}
              href={f.mediaItemUrl || ""}
              target="_blank"
              rel="noreferrer"
            >
              <li
                className={classNames({
                  [classes.courseFile]: true,
                  [classes.courseFileImage]: isImage,
                })}
              >
                {isImage ? (
                  <Image
                    src={f.mediaItemUrl}
                    style={{ width: 60, height: 60, objectFit: "contain" }}
                  />
                ) : (
                  <Description style={{ fontSize: 40 }} />
                )}
                <Typography className={classes.courseFileTitle}>
                  {f.title}
                </Typography>
                <Button color="primary">
                  <GetApp />
                </Button>
              </li>
            </a>
          );
        })}
      </ul>
    </div>
  );
};
