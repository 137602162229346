import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface RegisterFields {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
}

interface UseRegisterFormOptions {
  initialValues?: RegisterFields;
  onSubmit: (
    values: RegisterFields,
    formikHelpers: FormikHelpers<RegisterFields>
  ) => Promise<any>;
}

export const useRegisterFormik = (options: UseRegisterFormOptions) => {
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required("Kjo fushë është e nevojshme"),
    lastName: Yup.string().required("Kjo fushë është e nevojshme"),
    username: Yup.string().required("Kjo fushë është e nevojshme"),
    email: Yup.string()
      .email("Ju lutemi shkruani një email të vlefshëm!")
      .required("Kjo fushë është e nevojshme"),
    password: Yup.string()
      .min(6, "Fjalëkalimi duhet të jetë më shumë se 6 shkronja")
      .required("Kjo fushë është e nevojshme"),
  });

  return useFormik<RegisterFields>({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RegisterFormik = ReturnType<typeof useRegisterFormik>;
