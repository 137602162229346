import React from "react";
import { Page } from "components/shared/Page/Page";
import { Slider } from "components/home/Slider";
import { Courses } from "components/home/Courses";
import { About } from "components/home/About";

export const Home = () => {
  return (
    <Page animate>
      <Slider />
      <Courses />
      <About />
    </Page>
  );
};
