import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Person, ExitToApp } from "@material-ui/icons";
import Button from "components/shared/Button/Button";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { Menu, MenuItem } from "@material-ui/core";
import { useRouter } from "lib/hooks/useRouter";

const useStyles = makeStyles({
  userDropdown: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  menu: {
    "& .MuiPaper-root": {
      padding: "5px 10px",
      minWidth: 100,
    },
    "& .MuiSvgIcon-root": {
      marginRight: 5,
      width: 20,
    },
  },
});

export const UserDropdown = () => {
  const classes = useStyles();
  const router = useRouter();
  const authCtx = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!authCtx.user) {
    return null;
  }

  return (
    <div className={classes.userDropdown}>
      <Button
        variant="text"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        startIcon={<Person />}
      >
        {authCtx.user?.firstName} {authCtx.user?.lastName}
      </Button>

      <Menu
        variant="menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        className={classes.menu}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disableGutters
          onClick={() => {
            handleClose();
            router.history.push("/profile");
          }}
        >
          <Person /> Profili
        </MenuItem>

        <MenuItem disableGutters onClick={authCtx.logout}>
          <ExitToApp /> Dil
        </MenuItem>
      </Menu>
    </div>
  );
};
