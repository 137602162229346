import React from "react";
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Container from "components/shared/Container/Container";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { Facebook, YouTube, Instagram } from "@material-ui/icons";
import { Animate } from "components/shared/Animate/Animate";

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      background: "#fff",
      textAlign: "center",
      borderTop: "1px solid #eee",
    },
    footerLinks: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "space-between",
      height: "100%",
      "& ul": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        padding: "30px 0",
        "& li": {
          margin: "0 10px",
        },
      },
    },
    internalLinks: {
      "& a:hover": {
        color: theme.palette.primary.light,
      },
    },
    socialMedia: {
      "& svg": {
        width: "30px",
        height: "30px",
      },
      "& svg:hover": {
        color: theme.palette.primary.light,
      },
    },
    copyRight: {
      backgroundColor: "#eee",
      padding: 10,
      marginTop: 5,
    },
  })
);
const Footer = () => {
  const classes = useStyles();
  return (
    <Animate animate="bottom-up">
      <footer className={classes.footer}>
        <Container>
          <Grid container spacing={4}>
            <Grid item md={4}>
              <Logo style={{ width: 120 }} />
              <Typography align="left">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum
                magnam sit totam. Et quaerat reprehenderit saepe.
              </Typography>
            </Grid>
            <Grid item md={8}>
              <div className={classes.footerLinks}>
                <ul className={classes.internalLinks}>
                  <li>
                    <Link to="/about">
                      <Typography>Rreth nesh</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <Typography>Kontakti</Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      <Typography>Termat dhe Kushtet </Typography>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <Typography>Politikat e privatësisë </Typography>
                    </Link>
                  </li>
                </ul>
                <ul className={classes.socialMedia}>
                  <li>
                    <a href="/">
                      <Facebook />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <Instagram />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <YouTube />
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Container>
        <div className={classes.copyRight}>
          <Typography>Copyright 2021 - Maluki</Typography>
        </div>
      </footer>
    </Animate>
  );
};

export default Footer;
