
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Commenter": [
      "CommentAuthor",
      "User"
    ],
    "ContentNode": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "ContentRevisionUnion": [
      "Post",
      "Page",
      "Course",
      "Lesson"
    ],
    "ContentTemplate": [
      "DefaultTemplate"
    ],
    "Course_Courseoptions_Lessons": [
      "Lesson"
    ],
    "DatabaseIdentifier": [
      "Category",
      "Comment",
      "Course",
      "Forum",
      "Kategoria",
      "Lesson",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Post",
      "PostFormat",
      "Tag",
      "User"
    ],
    "EnqueuedAsset": [
      "EnqueuedScript",
      "EnqueuedStylesheet"
    ],
    "HierarchicalContentNode": [
      "MediaItem",
      "Page"
    ],
    "HierarchicalTermNode": [
      "Category",
      "Kategoria"
    ],
    "MenuItemLinkable": [
      "Category",
      "Course",
      "Forum",
      "Kategoria",
      "Lesson",
      "Page",
      "Post",
      "Tag"
    ],
    "MenuItemObjectUnion": [
      "Post",
      "Page",
      "Forum",
      "Course",
      "Lesson",
      "Category",
      "Tag",
      "Kategoria"
    ],
    "Node": [
      "Category",
      "Comment",
      "CommentAuthor",
      "ContentType",
      "Course",
      "EnqueuedScript",
      "EnqueuedStylesheet",
      "Forum",
      "Kategoria",
      "Lesson",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Plugin",
      "Post",
      "PostFormat",
      "Tag",
      "Taxonomy",
      "Theme",
      "User",
      "UserRole"
    ],
    "NodeWithAuthor": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithComments": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithContentEditor": [
      "Course",
      "Forum",
      "Lesson",
      "Page",
      "Post"
    ],
    "NodeWithExcerpt": [
      "Course",
      "Forum",
      "Lesson",
      "Post"
    ],
    "NodeWithFeaturedImage": [
      "Course",
      "Forum",
      "Lesson",
      "Page",
      "Post"
    ],
    "NodeWithPageAttributes": [
      "Page"
    ],
    "NodeWithRevisions": [
      "Course",
      "Lesson",
      "Page",
      "Post"
    ],
    "NodeWithTemplate": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithTitle": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithTrackbacks": [
      "Post"
    ],
    "PostObjectUnion": [
      "Post",
      "Page",
      "MediaItem",
      "Forum",
      "Course",
      "Lesson"
    ],
    "TermNode": [
      "Category",
      "Kategoria",
      "PostFormat",
      "Tag"
    ],
    "TermObjectUnion": [
      "Category",
      "Tag",
      "PostFormat",
      "Kategoria"
    ],
    "UniformResourceIdentifiable": [
      "Category",
      "ContentType",
      "Course",
      "Forum",
      "Kategoria",
      "Lesson",
      "MediaItem",
      "Page",
      "Post",
      "PostFormat",
      "Tag",
      "User"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Commenter": [
      "CommentAuthor",
      "User"
    ],
    "ContentNode": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "ContentRevisionUnion": [
      "Post",
      "Page",
      "Course",
      "Lesson"
    ],
    "ContentTemplate": [
      "DefaultTemplate"
    ],
    "Course_Courseoptions_Lessons": [
      "Lesson"
    ],
    "DatabaseIdentifier": [
      "Category",
      "Comment",
      "Course",
      "Forum",
      "Kategoria",
      "Lesson",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Post",
      "PostFormat",
      "Tag",
      "User"
    ],
    "EnqueuedAsset": [
      "EnqueuedScript",
      "EnqueuedStylesheet"
    ],
    "HierarchicalContentNode": [
      "MediaItem",
      "Page"
    ],
    "HierarchicalTermNode": [
      "Category",
      "Kategoria"
    ],
    "MenuItemLinkable": [
      "Category",
      "Course",
      "Forum",
      "Kategoria",
      "Lesson",
      "Page",
      "Post",
      "Tag"
    ],
    "MenuItemObjectUnion": [
      "Post",
      "Page",
      "Forum",
      "Course",
      "Lesson",
      "Category",
      "Tag",
      "Kategoria"
    ],
    "Node": [
      "Category",
      "Comment",
      "CommentAuthor",
      "ContentType",
      "Course",
      "EnqueuedScript",
      "EnqueuedStylesheet",
      "Forum",
      "Kategoria",
      "Lesson",
      "MediaItem",
      "Menu",
      "MenuItem",
      "Page",
      "Plugin",
      "Post",
      "PostFormat",
      "Tag",
      "Taxonomy",
      "Theme",
      "User",
      "UserRole"
    ],
    "NodeWithAuthor": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithComments": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithContentEditor": [
      "Course",
      "Forum",
      "Lesson",
      "Page",
      "Post"
    ],
    "NodeWithExcerpt": [
      "Course",
      "Forum",
      "Lesson",
      "Post"
    ],
    "NodeWithFeaturedImage": [
      "Course",
      "Forum",
      "Lesson",
      "Page",
      "Post"
    ],
    "NodeWithPageAttributes": [
      "Page"
    ],
    "NodeWithRevisions": [
      "Course",
      "Lesson",
      "Page",
      "Post"
    ],
    "NodeWithTemplate": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithTitle": [
      "Course",
      "Forum",
      "Lesson",
      "MediaItem",
      "Page",
      "Post"
    ],
    "NodeWithTrackbacks": [
      "Post"
    ],
    "PostObjectUnion": [
      "Post",
      "Page",
      "MediaItem",
      "Forum",
      "Course",
      "Lesson"
    ],
    "TermNode": [
      "Category",
      "Kategoria",
      "PostFormat",
      "Tag"
    ],
    "TermObjectUnion": [
      "Category",
      "Tag",
      "PostFormat",
      "Kategoria"
    ],
    "UniformResourceIdentifiable": [
      "Category",
      "ContentType",
      "Course",
      "Forum",
      "Kategoria",
      "Lesson",
      "MediaItem",
      "Page",
      "Post",
      "PostFormat",
      "Tag",
      "User"
    ]
  }
};
      export default result;
    