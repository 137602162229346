import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { TextField } from "components/shared/TextField/TextField";
import Button from "components/shared/Button/Button";
import { useForgotPasswordFormik } from "lib/hooks/formik/useForgotPasswordFormik";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import { useRouter } from "lib/hooks/useRouter";
import { useForgotPasswordMutation } from "../../../graphql/generated/generated";

const useStyles = makeStyles({
  forgotPasswordForm: {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },

  paper: {
    padding: "30px",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  forgotPasswordActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 15,
    "& a": {
      textDecoration: "none",
      borderBottom: "1px solid #333",
    },
  },
  forgotPasswordWithEmail: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const ForgotPasswordFormm = () => {
  const classes = useStyles();
  const errorHandler = useErrorHandler();
  const snackbar = useSnackbar();
  const router = useRouter();
  const [forgotPassword] = useForgotPasswordMutation();

  const formik = useForgotPasswordFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, formikHelpers) => {
      console.log("here", values);
      try {
        errorHandler.reset();
        formikHelpers.setErrors({});

        if (!values.email) return;

        await forgotPassword({
          variables: {
            email: values.email,
          },
        });

        formikHelpers.resetForm();

        snackbar.info(
          "Emaili është dërguar me sukses në adresën që keni dhënë"
        );

        router.history.push("/login");
      } catch (e) {
        errorHandler.handleError(e);
        formikHelpers.setErrors({ email: "" });
      }
    },
  });

  const { errors, touched } = formik;

  return (
    <div className={classes.forgotPasswordForm}>
      <Paper className={classes.paper}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <ErrorMessage
            showCloseButton
            error={errorHandler.error}
            alertProps={{ style: { marginBottom: 15, width: "100%" } }}
            onClose={() => {
              errorHandler.reset();
              formik.setErrors({});
            }}
          />

          <TextField
            fullWidth
            type="text"
            name="email"
            id="email"
            label="Email"
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : undefined}
            value={formik.values.email || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div className={classes.forgotPasswordActions}>
            <div className={classes.forgotPasswordWithEmail}>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                color="primary"
              >
                {formik.isSubmitting ? "Ju lutem prisni" : "Dërgo"}
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
};
