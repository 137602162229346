import React from "react";
import { RouteProps, Switch } from "react-router-dom";

import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { RestrictedRoute } from "./RestrictedRoute";

import { Page404 } from "pages/Page404/Page404";
import { Login } from "pages/Login/Login";
import { Register } from "pages/Register/Register";
import { ForgotPassword } from "pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "pages/ResetPassword/ResetPassword";
import { Profile } from "pages/Profile/Profile";
import { Home } from "pages/Home/Home";
import { Forum } from "pages/Forum/Forum";
import { ForumSingle } from "pages/Forum/ForumSingle";
import { Course } from "pages/Course/Course";
import { Courses } from "pages/Courses/Courses";
import { Lessons } from "pages/Lessons/Lessons";
import { Lesson } from "pages/Lesson/Lesson";
import { About } from "pages/About/About";
import { Contact } from "pages/Contact/Contact";
import { PrivacyPolicy } from "pages/PrivacyPolicy/PrivacyPolicy";
import { TermsAndConditions } from "pages/TermsAndConditions/TermsAndConditions";
import { AccountVerification } from "pages/AccountVerification/AccountVerification";
import { SearchResults } from "../pages/SearchResults/SearchResults";

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}
interface AppRoute extends RouteProps {
  type?: RouteType;
}
export const AppRoutes: AppRoute[] = [
  // Restricted Routes
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "login",
    component: Login,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "register",
    component: Register,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "reset-password",
    component: ResetPassword,
  },
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: "account-verification",
    component: AccountVerification,
  },

  // Private Routes
  {
    type: RouteType.PRIVATE,
    exact: false,
    path: "profile",
    component: Profile,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "forum",
    component: Forum,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "forum/:slug",
    component: ForumSingle,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "lessons",
    component: Lessons,
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: "lesson/:lessonSlug",
    component: Lesson,
  },

  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "/",
    component: Home,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "course/:courseSlug/:lessonSlug?",
    component: Course,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "courses",
    component: Courses,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "about",
    component: About,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "contact",
    component: Contact,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "search",
    component: SearchResults,
  },
];

export const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r;

        if (type === RouteType.PRIVATE) {
          return (
            <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          );
        }

        if (type === RouteType.RESTRICTED) {
          return (
            <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          );
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />;
      })}
      <PublicRoute component={Page404} />
    </Switch>
  );
};
