import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CourseVideo } from "components/course/ui/CourseVideo";
import { CourseLessons } from "components/course/ui/CourseLessons";
import { CourseFiles } from "components/course/ui/CourseFiles";
import {
  LessonFragment,
  useGetCourseBySlugQuery,
  MediaItem,
} from "graphql/generated/generated";
import { Loader } from "components/shared/Loader/Loader";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { CourseInfo } from "components/course/ui/CourseInfo";
import { RedirectToFirstLesson } from "components/course/lib/RedirectToFirstLesson";
import { CourseNotFound } from "components/course/ui/CourseNotFound";
import { LessonComments } from "components/course/ui/LessonComments";
import { RelatedCourses } from "components/course/ui/RelatedCourses";

const useStyles = makeStyles({
  course: {
    width: "100%",
    height: "100%",
  },
});

interface Props {
  courseSlug: string;
  lessonSlug?: string;
}

export const Course = (props: Props) => {
  const classes = useStyles();
  const { data, loading, error } = useGetCourseBySlugQuery({
    variables: {
      slug: props.courseSlug,
    },
  });

  if (loading) return <Loader />;
  if (error) return <ErrorMessage error={error} />;

  const course = data?.courseBy;
  const courseLessons = (course?.courseOptions?.lessons ||
    []) as LessonFragment[];

  const activeLesson =
    courseLessons && props.lessonSlug
      ? courseLessons.find((l) => l.slug === props.lessonSlug)
      : null;

  const activeLessonVideoLink =
    activeLesson?.lessonOptions?.video?.mediaItemUrl;

  const activeLessonFiles = activeLesson?.lessonOptions?.files?.map(
    (f) => f?.file
  );
  return (
    <div className={classes.course}>
      <Grid container alignItems="flex-start">
        {!course ? (
          <CourseNotFound />
        ) : (
          <>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={12} md={9}>
                <CourseVideo
                  source={
                    activeLessonVideoLink ||
                    course?.courseOptions?.video?.mediaItemUrl
                  }
                />

                {!activeLesson && courseLessons?.length > 0 && (
                  <RedirectToFirstLesson lessons={courseLessons} />
                )}

                <CourseInfo
                  title={activeLesson?.title ?? course?.title}
                  content={activeLesson?.content}
                />

                <CourseFiles
                  title="Dosjet e mësimit:"
                  files={activeLessonFiles as MediaItem[]}
                />

                {props.lessonSlug && activeLesson && (
                  <LessonComments
                    slug={props.lessonSlug}
                    lessonId={activeLesson?.databaseId}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <CourseLessons lessons={courseLessons} />
              </Grid>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <RelatedCourses />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
