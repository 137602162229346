import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {
  OrderEnum,
  PostObjectsConnectionOrderbyEnum,
  useGetForumsQuery,
} from "graphql/generated/generated";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { ForumListItem } from "components/forum/ui/ForumListItem";
import { Loader } from "components/shared/Loader/Loader";
import CreateForum from "components/forum/ui/CreateForum";

const useStyles = makeStyles({
  forumFilters: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    alignItems: "flex-end",
  },
});

export const ForumList = () => {
  const classes = useStyles();

  const [order, setOrder] = useState<string>("DESC");

  const { data, loading, error } = useGetForumsQuery({
    variables: {
      field: "DATE" as PostObjectsConnectionOrderbyEnum,
      order: order === "DESC" ? OrderEnum.Desc : OrderEnum.Asc,
    },
  });

  if (error) return <ErrorMessage error={error} />;

  const forums = data?.forums?.nodes;

  return (
    <div>
      <CreateForum />

      <div className={classes.forumFilters}>
        <FormControl style={{ maxWidth: 400, width: "100%" }}>
          <InputLabel>Rendit:</InputLabel>
          <Select
            disabled={loading}
            value={order}
            onChange={(e) => setOrder(e.target.value as string)}
          >
            <MenuItem value="DESC">Nga më të rejat</MenuItem>
            <MenuItem value="ASC">Nga më të vjetrat</MenuItem>
          </Select>
        </FormControl>
      </div>

      {loading ? (
        <Loader />
      ) : forums && forums.length > 0 ? (
        forums.map((forumItem) =>
          forumItem ? (
            <ForumListItem key={forumItem.id} forumItem={forumItem} />
          ) : null
        )
      ) : (
        <Typography>Nuk u gjet asnje postim!</Typography>
      )}
    </div>
  );
};
