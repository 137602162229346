import React from "react";
import { Button as MUIButton, ButtonProps } from "@material-ui/core";
import { useRouter } from "lib/hooks/useRouter";

interface Props extends ButtonProps {
  to?: string;
}

export default function Button(props: Props) {
  const router = useRouter();
  const { onClick, to } = props;

  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (to) {
      router.history.push(to);
      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <MUIButton {...props} onClick={(e) => handleOnClick(e)}>
      {props.children}
    </MUIButton>
  );
}
