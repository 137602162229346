import React from "react";
import { LessonFragment } from "graphql/generated/generated";
import { Redirect } from "react-router-dom";
import useCourseRouter from "lib/hooks/useCourseRouter";

interface Props {
  lessons: LessonFragment[];
}

export const RedirectToFirstLesson = (props: Props) => {
  const router = useCourseRouter();

  const firstLesson = props.lessons ? props.lessons[0] : null;

  if (!firstLesson) <Redirect to="/" />;

  return (
    <Redirect
      to={`/course/${router.match.params.courseSlug}/${firstLesson?.slug}`}
    />
  );
};
