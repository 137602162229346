import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Page } from "components/shared/Page/Page";
import { ProfileContent } from "components/user/Profile/Profile";

export const Profile = (props: RouteComponentProps) => {
  return (
    <Page animate>
      <ProfileContent />
    </Page>
  );
};
