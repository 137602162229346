import React from "react";
import { Page } from "components/shared/Page/Page";
import { ForumSingleContent } from "components/forum/ForumSingleContent";
import { RouteComponentProps } from "react-router-dom";

interface Props {
  slug: string;
}

export const ForumSingle = (props: RouteComponentProps<Props>) => {
  const { slug } = props.match.params;

  return (
    <Page animate showBackButton>
      <ForumSingleContent slug={slug} />
    </Page>
  );
};
