import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Page } from "components/shared/Page/Page";
import { Lessons as LessonsContent } from "components/lessons/Lessons";

interface Props {}

export const Lessons: React.FC<RouteComponentProps<Props>> = () => {
  return (
    <Page animate>
      <LessonsContent />
    </Page>
  );
};
