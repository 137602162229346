import { useUploadMediaMutation } from "graphql/generated/generated";
import { convertFileToBase64 } from "lib/helpers/convertFileToBase64";

const useUploadFile = () => {
  const [uploadMedia] = useUploadMediaMutation();

  const _filesToBase64 = async (files: File[]) => {
    let response: any = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const base64File = await convertFileToBase64(file);
      response.push(base64File);
    }

    return response;
  };

  return async (files: File[]) => {
    if (!files) {
      console.error("No files provided");
      return;
    }

    const base64Files = await _filesToBase64(files);

    if (!base64Files) return;

    try {
      const res = await uploadMedia({
        variables: {
          files: base64Files,
        },
      });

      return res.data?.uploadMedia?.fileIds || [];
    } catch (e) {}
  };
};

export default useUploadFile;
