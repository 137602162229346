import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Skeleton from "@material-ui/lab/Skeleton";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CourseListItemFragment } from "graphql/generated/generated";
import placeholder from "assets/images/placeholder.jpg";
import { useRouter } from "lib/hooks/useRouter";

interface CourseListItemProps {
  loading?: boolean;
  item: CourseListItemFragment;
}

const useStyles = makeStyles({
  root: {
    padding: 0,
  },
  media: {
    height: 200,
  },
  lessonsLength: {
    color: "#6a6f73",
    fontSize: 14,
  },
});

export const CourseListItem = (props: CourseListItemProps) => {
  const { item } = props;
  const classes = useStyles();
  const router = useRouter();

  const lessonsLength = item?.courseOptions?.lessons?.length;

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
      <Card component="div" className={classes.root}>
        <CardActionArea
          onClick={() => router.history.push(`/course/${item.slug}`)}
        >
          {item ? (
            <CardMedia
              className={classes.media}
              image={item.featuredImage?.node?.mediaItemUrl || placeholder}
              title="Contemplative Reptile"
            />
          ) : (
            <Skeleton variant="rect" height={200} />
          )}
          <CardContent>
            {item ? (
              <Typography variant="h6">
                <strong>{item.title}</strong>
              </Typography>
            ) : (
              <Skeleton height={30} />
            )}

            {item ? (
              <Typography className={classes.lessonsLength}>
                Mësime: {lessonsLength || "Nuk ka mësime"}
              </Typography>
            ) : (
              <Skeleton width="50%" />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
