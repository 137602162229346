import React from "react";
import classNames from "classnames";
import * as Yup from "yup";
import { TextField } from "components/shared/TextField/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { Grid, FormHelperText } from "@material-ui/core";
import Button from "components/shared/Button/Button";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import {
  useAttachMediaToForumMutation,
  useCreateForumMutation,
} from "graphql/generated/generated";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import FilePicker from "components/shared/FilePicker/FilePicker";
import useUploadFile from "lib/hooks/useUploadFile";
import { Maybe } from "graphql/jsutils/Maybe";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ReactQuill.scss";

const useStyles = makeStyles((theme) => {
  return createStyles({
    addComment: {
      marginBottom: 50,
    },
    errorMessage: {
      marginBottom: 10,
    },
    bottomWrapper: {
      marginTop: 10,
      display: "flex",
      justifyContent: "flex-end",
    },
    reactQuill: {
      position: "relative",
      margin: "10px 0 20px 0",
    },
    reactQuillError: {
      borderColor: "red",
      "& .ql-toolbar, .ql-container": {
        borderColor: theme.palette.error.light,
      },
    },
  });
});

interface Props {
  onSuccess?: () => void;
}

interface ForumFields {
  title: string;
  content: string;
  files: any;
}

const CreateForum = (props: Props) => {
  const classes = useStyles();
  const errorHandler = useErrorHandler();
  const snackbar = useSnackbar();
  const uploadFiles = useUploadFile();
  const [createForum] = useCreateForumMutation();
  const [attachMediaToForum] = useAttachMediaToForumMutation();

  const formik = useFormik<ForumFields>({
    initialValues: {
      content: "",
      title: "",
      files: null,
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      title: Yup.string().trim().required("Kjo fushë është e nevojshme"),
      content: Yup.string()
        .trim()
        .required("Kjo fushë është e nevojshme")
        .test("content", "Kjo fushë është e nevojshme", function (value) {
          return !(
            !value ||
            value === "<p><br></p>" ||
            value?.replace(/>\s+</g, "") === "<p/p>"
          );
        }),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        errorHandler.reset();

        // First we create the forum
        const createForumResponse = await createForum({
          variables: {
            title: values.title,
            content: values.content,
          },
        });

        const forumId =
          createForumResponse.data?.createForum?.forum?.databaseId;

        // then if we have files, we upload files and attach uploaded files to current forum databaseid.
        const files = values.files;

        if (forumId && files && files?.length > 0) {
          const uploadedFileIds = await uploadFiles(files);

          if (!uploadedFileIds) return;

          await attachMediaToForum({
            variables: {
              forumId: forumId,
              fileIds: uploadedFileIds as Maybe<number | number[]>,
            },
          });
        }

        snackbar.info("Posti juaj eshte krijuar me sukses!");

        if (props.onSuccess) {
          props.onSuccess();
        }

        formikHelpers.resetForm();
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const { touched, values, errors } = formik;

  return (
    <div className={classes.addComment}>
      <Grid>
        <Grid item md={12}>
          <ErrorMessage
            error={errorHandler.error}
            showCloseButton
            className={classes.errorMessage}
            onClose={() => {
              formik.setErrors({});
              errorHandler.reset();
            }}
          />

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              name="title"
              label="Titulli"
              value={values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={touched.title && !!errors.title}
              helperText={touched.title ? errors.title : undefined}
            />
            <div className={classes.reactQuill}>
              <ReactQuill
                theme="snow"
                className={classNames({
                  [classes.reactQuillError]:
                    touched.content && !!errors.content,
                })}
                value={values.content}
                onChange={(e) => {
                  formik.setFieldValue("content", e);
                }}
              />
              {touched.content && errors.content ? (
                <FormHelperText className=" Mui-error quill--error">
                  {errors.content}
                </FormHelperText>
              ) : undefined}
            </div>

            <FilePicker
              onSelect={(files) => {
                formik.setFieldValue("files", files);
                formik.setFieldTouched("files");
              }}
            />

            <div className={classes.bottomWrapper}>
              <Button
                type="submit"
                color="primary"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Ju lutem prisni" : "Ruaj"}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateForum;
