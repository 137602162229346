import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CommentFragment } from "graphql/generated/generated";
import Comment from "components/comments/Comment";
import { DocumentNode } from "graphql";

interface Props {
  queryDocument: DocumentNode;
  comments: CommentFragment[];
}

const useStyles = makeStyles({
  comments: {
    marginTop: 50,
    paddingTop: 30,
  },
});

export const Comments = (props: Props) => {
  const classes = useStyles();

  const comments = props.comments || [];

  if (!comments || !Array.isArray(comments) || comments.length === 0)
    return null;

  return (
    <div className={classes.comments}>
      <ul>
        {comments.map((x, i) =>
          !x ? null : (
            <Comment
              key={`${x}-${i}`}
              comment={x}
              queryDocument={props.queryDocument}
            />
          )
        )}
      </ul>
    </div>
  );
};
