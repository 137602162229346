import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { ExitToApp } from "@material-ui/icons";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { profileRoutes } from "components/user/Profile/Profile";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      borderRadius: 4,
      padding: 10,
      backgroundColor: theme.palette.background.default,
      "& .MuiButtonBase-root": {
        margin: "5px 0",
      },
      "& .MuiListItemIcon-root": {
        minWidth: 40,
      },
      "& a": {
        color: "#000",
      },
      "& a.active .MuiButtonBase-root": {
        background: theme.palette.info.dark,
        color: "#fff",
        "& svg": {
          color: "#fff",
        },
      },
    },
  })
);

export const ProfileSidebar = () => {
  const classes = useStyles();
  const authCtx = useAuthContext();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {profileRoutes.map((x, i) => {
          const Icon = x.icon;
          return (
            <NavLink to={`/profile/${x.path}`} key={`${x.title}-${i}`}>
              <ListItem button>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={x.title} />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button color="secondary" onClick={authCtx.logout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Dil" />
        </ListItem>
      </List>
    </div>
  );
};
