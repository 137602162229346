import React from "react";
import { Page } from "components/shared/Page/Page";

export const Page404 = () => {
  return (
    <Page animate>
      <h1>
        Nothing found <b>404</b>!
      </h1>
    </Page>
  );
};
