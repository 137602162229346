import React from "react";
import { Grid } from "@material-ui/core";
import { LessonsCategories } from "components/lessons/ui/LessonsCategories";
import { LessonsByCategory } from "components/lessons/ui/LessonsByCategory";

interface Props {}

export const Lessons: React.FC<Props> = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={3}>
        <LessonsCategories />
      </Grid>
      <Grid item md={9}>
        <LessonsByCategory />
      </Grid>
    </Grid>
  );
};
