import * as React from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AccountCircle, ExitToApp, Menu } from "@material-ui/icons";
import { useState } from "react";
import Button from "components/shared/Button/Button";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { Search } from "components/partials/Search/Search";

const useStyles = makeStyles({
  mobileDrawerWrapper: {
    width: 300,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `black`,
  },
  bottomList: {
    marginTop: "auto",

    "& .MuiListItem-root": {
      paddingBottom: 0,
      paddingTop: 0,
    },
    "& button": {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
});

interface Props {
  navLinks: any;
}

const MobileDrawer = (props: Props) => {
  const classes = useStyles();
  const authCtx = useAuthContext();
  const [state, setState] = useState(false);

  const { navLinks } = props;

  const toggleDrawer = () => setState((s) => !s);

  return (
    <React.Fragment>
      <IconButton edge="start" aria-label="menu" onClick={toggleDrawer}>
        <Menu fontSize="large" />
      </IconButton>

      <Drawer
        anchor="right"
        open={state}
        onChange={toggleDrawer}
        onClose={toggleDrawer}
      >
        <div className={classes.mobileDrawerWrapper}>
          <Search />

          <div
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            <List component="nav">
              {navLinks.map((x: any) => (
                <Link to={x.path} key={x.title} className={classes.linkText}>
                  <ListItem button>
                    <ListItemText primary={x.title} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </div>

          <div
            className={classes.bottomList}
            role="presentation"
            onClick={toggleDrawer}
          >
            <List>
              {authCtx.isAuthenticated ? (
                <>
                  <ListItem disableGutters>
                    <Button variant="text" startIcon={<AccountCircle />}>
                      {authCtx.user?.firstName} {authCtx.user?.lastName}
                    </Button>
                  </ListItem>
                  <ListItem disableGutters>
                    <Button
                      variant="text"
                      startIcon={<ExitToApp />}
                      onClick={authCtx.logout}
                    >
                      Dil
                    </Button>
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem disableGutters>
                    <Button
                      variant="text"
                      startIcon={<AccountCircle />}
                      to="/login"
                    >
                      Kyqu
                    </Button>
                  </ListItem>

                  <ListItem disableGutters>
                    <Button
                      variant="text"
                      startIcon={<AccountCircle />}
                      to="/register"
                    >
                      Regjistrohu
                    </Button>
                  </ListItem>
                </>
              )}
            </List>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default MobileDrawer;
