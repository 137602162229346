import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useLoginMutation } from "graphql/generated/generated";
import { useLoginFormik } from "lib/hooks/formik/useLoginFormik";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { TextField } from "components/shared/TextField/TextField";
import Button from "components/shared/Button/Button";
import { SocialMediaLogin } from "components/user/SocialMediaLogin/SocialMediaLogin";
import { useSnackbar } from "../../../lib/context/SnackbarContext/SnackbarContext";

const useStyles = makeStyles({
  loginForm: {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },

  paper: {
    padding: "30px",
  },

  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  loginActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 15,
    "& a": {
      textDecoration: "none",
      borderBottom: "1px solid #333",
    },
  },
  loginWithEmail: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const LoginForm = () => {
  const classes = useStyles();
  const authCtx = useAuthContext();
  const errorHandler = useErrorHandler();
  const snackbar = useSnackbar();
  const [login] = useLoginMutation();

  const [showPassword, setShowPassword] = useState(false);

  const formik = useLoginFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        errorHandler.reset();

        const res = await login({
          variables: {
            username: values.email || "",
            password: values.password || "",
          },
        });

        const user = res.data?.login?.user;

        if (!user) {
          snackbar.error("Dicka shkoi gabim. Provoni perseri");
          return;
        }

        if (!user?.userOptions?.accountActivated) {
          snackbar.error("Ju lutem verifikoni llogarine tuaj.");
          return;
        }

        authCtx.login(user);
        formikHelpers.resetForm();
      } catch (e) {
        errorHandler.handleError(e);
        formikHelpers.setErrors({ email: " ", password: " " });
      }
    },
  });

  const { errors, touched } = formik;

  return (
    <div className={classes.loginForm}>
      <Paper className={classes.paper}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <ErrorMessage
            showCloseButton
            error={errorHandler.error}
            errorText="Email ose fjalekalimi jane gabim!"
            alertProps={{ style: { marginBottom: 15, width: "100%" } }}
            onClose={() => {
              errorHandler.reset();
              formik.setErrors({});
            }}
          />

          <TextField
            fullWidth
            type="text"
            name="email"
            id="email"
            label="Email"
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : undefined}
            value={formik.values.email || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            label="Password"
            error={touched.password && !!errors.password}
            helperText={touched.password ? errors.password : undefined}
            value={formik.values.password || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className={classes.loginActions}>
            <div className={classes.loginWithEmail}>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                color="primary"
              >
                {formik.isSubmitting ? "Ju lutem prisni" : "Kyqu"}
              </Button>
              <Link style={{ marginLeft: 5 }} to="/forgot-password">
                Keni harruar fjalëkalimin ?
              </Link>
            </div>

            <SocialMediaLogin />

            <div style={{ marginTop: 20 }}>
              <Link to="/register">
                <Typography>Regjistrohu </Typography>
              </Link>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
};
