import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import Button from "components/shared/Button/Button";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import { Animate } from "components/shared/Animate/Animate";
import { useConfirmation } from "lib/context/ConfirmationContext/ConfirmationContext";
import { makeStyles } from "@material-ui/core/styles";
import { useDeleteUserMutation } from "graphql/generated/generated";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";

const useStyles = makeStyles({
  paper: {
    padding: 20,
  },
  list: {
    marginTop: 20,
    listStyle: "disc",
    paddingLeft: 20,
  },
});

export const ProfileDeleteAccount = () => {
  const classes = useStyles();
  const authCtx = useAuthContext();
  const confirmation = useConfirmation();
  const errorHandler = useErrorHandler();
  const [deleteUser, { loading }] = useDeleteUserMutation();
  const snack = useSnackbar();

  const handleDelete = () => {
    confirmation
      .confirm("A jeni i sigurt për të çaktivizuar llogarinë tuaj ?")
      .then(async () => {
        try {
          if (!authCtx.user?.id) return;

          await deleteUser({
            variables: {
              id: authCtx.user.id,
            },
          });

          authCtx.logout();

          snack.info("Llogaria u fshi me sukses.");
        } catch (e) {
          errorHandler.handleError(e);
        }
      })
      .catch(() => {});
  };

  return (
    <Animate animate="right-to-left">
      <Paper className={classes.paper}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={3}>
            <Grid item style={{ width: "100%" }}>
              <Typography variant="h5" gutterBottom>
                Çaktivizo llogarinë
              </Typography>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Aperiam at consequatur consequuntur cum cupiditate debitis
                dolore harum ipsa, ipsum perferendis reiciendis, rerum sequi
                tempora vitae.
              </Typography>
              <ul className={classes.list}>
                <li>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  </Typography>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                disabled={loading}
                color="secondary"
                style={{ marginTop: 9 }}
                onClick={handleDelete}
              >
                {loading ? "Ju lutem prisni" : "Çaktivizo"}
              </Button>
            </Grid>

            {errorHandler.error && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ErrorMessage error={errorHandler.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Animate>
  );
};
