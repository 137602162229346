import React from "react";
import { Grid } from "@material-ui/core";
import { Alert } from "components/shared/Alert/Alert";

export const LessonNotFound = () => {
  return (
    <Grid item style={{ width: "100%" }}>
      <Alert severity="warning">Mësimi nuk u gjet!</Alert>
    </Grid>
  );
};
