import React from "react";
import * as Yup from "yup";
import { Grid, Paper } from "@material-ui/core";
import { TextField } from "components/shared/TextField/TextField";
import Button from "components/shared/Button/Button";
import { useFormik } from "formik";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import { useChangePasswordMutation } from "graphql/generated/generated";
import { Animate } from "components/shared/Animate/Animate";

interface ProfileChangePasswordFields {
  password: string;
  passwordConfirmation: string;
}

export const ProfileChangePassword = () => {
  const errorHandler = useErrorHandler();
  const authCtx = useAuthContext();
  const snack = useSnackbar();
  const [changePassword] = useChangePasswordMutation();

  const userData = authCtx.user;

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik<ProfileChangePasswordFields>({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().trim().required("Kjo fushë është e nevojshme"),
      passwordConfirmation: Yup.string()
        .required()
        .trim()
        .oneOf([Yup.ref("password")], "Fjalëkalimi duhet të jetë i njëjtë"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setErrors({});
        const userId = userData?.id;

        if (!userId) return;

        await changePassword({
          variables: {
            id: userId,
            password: values.password,
          },
        });

        helpers.resetForm();

        snack.info("Fjalekalimi u përditësuan me sukses");
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const getFieldError = (field: keyof ProfileChangePasswordFields) => {
    return touched[field] && errors[field] ? errors[field] : undefined;
  };

  return (
    <Animate animate="right-to-left">
      <Paper>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                type="password"
                name="password"
                label="Fjalekalimi"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!getFieldError("password")}
                helperText={getFieldError("password")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                type="password"
                name="passwordConfirmation"
                label="Konfirmo fjalekalimin"
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!getFieldError("passwordConfirmation")}
                helperText={getFieldError("passwordConfirmation")}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !dirty || !isValid}
                style={{ marginTop: 9 }}
              >
                {!isSubmitting ? "Ruaj" : "Ju lutem prisni"}
              </Button>
            </Grid>

            {errorHandler.error && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ErrorMessage error={errorHandler.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Animate>
  );
};
