import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField } from "components/shared/TextField/TextField";
import Button from "components/shared/Button/Button";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useRegisterFormik } from "lib/hooks/formik/useRegisterFormik";
import { useRegisterUserMutation } from "graphql/generated/generated";
import { useRouter } from "lib/hooks/useRouter";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { SocialMediaLogin } from "components/user/SocialMediaLogin/SocialMediaLogin";
import { useSnackbar } from "../../../lib/context/SnackbarContext/SnackbarContext";

const useStyles = makeStyles({
  RegisterForm: {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      padding: "30px",
    },
  },
  paper: {
    padding: "30px",
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  RegisterActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 15,
    "& a": {
      textDecoration: "none",
      borderBottom: "1px solid #333",
    },
  },
  registerWithEmail: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});

export const RegisterForm = () => {
  const classes = useStyles();
  const router = useRouter();
  const errorHandler = useErrorHandler();
  const snackbar = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const [register] = useRegisterUserMutation();

  const formik = useRegisterFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        await register({
          variables: {
            ...values,
          },
        });
        snackbar.info(
          "Për t'u identifikuar, ju lutemi verifikoni emailin tuaj"
        );
        formikHelpers.resetForm();
        router.history.push("/login");
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const { touched, values, errors } = formik;

  return (
    <div className={classes.RegisterForm}>
      <Paper className={classes.paper}>
        <form onSubmit={formik.handleSubmit}>
          <ErrorMessage
            showCloseButton
            error={errorHandler.error}
            alertProps={{ style: { marginBottom: 15, width: "100%" } }}
            onClose={() => {
              errorHandler.reset();
              formik.setErrors({});
            }}
          />

          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="firstName"
                label="Emri"
                error={touched.firstName && !!errors.firstName}
                helperText={touched.firstName ? errors.firstName : undefined}
                value={values.firstName || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="lastName"
                label="Mbiemri"
                error={touched.lastName && !!errors.lastName}
                helperText={touched.lastName ? errors.lastName : undefined}
                value={values.lastName || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            type="text"
            name="username"
            label="Emri i perdoruesit"
            error={touched.username && !!errors.username}
            helperText={touched.username ? errors.username : undefined}
            value={values.username || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <TextField
            fullWidth
            type="text"
            name="email"
            id="email"
            label="Email"
            error={touched.email && !!errors.email}
            helperText={touched.email ? errors.email : undefined}
            value={formik.values.email || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            label="Fjalëkalimi"
            error={touched.password && !!errors.password}
            helperText={touched.password ? errors.password : undefined}
            value={formik.values.password || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className={classes.RegisterActions}>
            <div className={classes.registerWithEmail}>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                color="primary"
              >
                {formik.isSubmitting ? "Ju lutem prisni" : "Regjistrohu"}
              </Button>
            </div>

            <SocialMediaLogin />

            <div style={{ marginTop: 20 }}>
              <Link to="/login">
                <Typography>Kyqu</Typography>
              </Link>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  );
};
