import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { LoginForm } from "../../components/user/LoginForm/LoginForm";
import { Page } from "../../components/shared/Page/Page";
import { makeStyles } from "@material-ui/core/styles";

interface Props {}

const useStyles = makeStyles(() => ({
  Login: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export const Login = (props: RouteComponentProps<Props>) => {
  const classes = useStyles();
  return (
    <Page animate className={[classes.Login]}>
      <LoginForm />
    </Page>
  );
};
