import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  CourseListItemFragment,
  useGetRelatedCoursesQuery,
} from "graphql/generated/generated";
import { CourseListItem } from "components/course/ui/CourseListItem";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { makeStyles } from "@material-ui/core/styles";
import { SectionTitle } from "components/shared/SectionTitle/SectionTitle";

const useStyles = makeStyles({
  relatedCourses: {
    marginTop: 100,
  },
  relatedCoursesContainer: {
    marginTop: 10,
  },
});

export const RelatedCourses = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetRelatedCoursesQuery();

  if (error) return <ErrorMessage error={error} />;

  const courses = (data?.courses?.nodes || []) as CourseListItemFragment[];

  return (
    <div className={classes.relatedCourses}>
      <SectionTitle text="Kurse te ngjashme" />

      <Grid className={classes.relatedCoursesContainer} container spacing={4}>
        {(loading ? Array.from(new Array(6)) : courses)?.map(
          (item: CourseListItemFragment, index: number) => {
            return (
              <CourseListItem
                key={`course-list-item-${index}`}
                loading={loading}
                item={item}
              />
            );
          }
        )}
      </Grid>
    </div>
  );
};
