import React from "react";
import * as Yup from "yup";
import { Grid, Paper } from "@material-ui/core";
import { TextField } from "components/shared/TextField/TextField";
import Button from "components/shared/Button/Button";
import { useFormik } from "formik";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { useUpdateUserMutation } from "graphql/generated/generated";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import { Animate } from "components/shared/Animate/Animate";

interface Props {}

interface ProfileAccountFields {
  firstName: string;
  lastName: string;
}
export const ProfileAccount = (props: Props) => {
  const [updateUser] = useUpdateUserMutation();
  const errorHandler = useErrorHandler();
  const authCtx = useAuthContext();
  const snack = useSnackbar();

  const userData = authCtx.user;

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    dirty,
    isValid,
  } = useFormik<ProfileAccountFields>({
    initialValues: {
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required("Kjo fushë është e nevojshme"),
      lastName: Yup.string().trim().required("Kjo fushë është e nevojshme"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setErrors({});
        const userId = userData?.id;

        if (!userId) return;

        const res = await updateUser({
          variables: {
            id: userId,
            ...values,
          },
        });

        const user = res.data?.updateUser?.user;

        if (!user) return;

        helpers.resetForm({ values });
        authCtx.updateUser(user);
        snack.info("Të dhënat u përditësuan me sukses");
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const getFieldError = (field: keyof ProfileAccountFields) => {
    return touched[field] && errors[field] ? errors[field] : undefined;
  };

  return (
    <Animate animate="right-to-left">
      <Paper>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                name="firstName"
                label="Emri"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!getFieldError("firstName")}
                helperText={getFieldError("firstName")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                name="lastName"
                label="Mbiemri"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!getFieldError("lastName")}
                helperText={getFieldError("lastName")}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                disabled
                name="email"
                label="Email"
                value={authCtx.user?.email || ""}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                fullWidth
                disabled
                name="username"
                label="Emri i përdoruesit"
                value={authCtx.user?.username}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting || !dirty || !isValid}
                style={{ marginTop: 9 }}
              >
                {!isSubmitting ? "Ruaj" : "Ju lutem prisni"}
              </Button>
            </Grid>

            {errorHandler.error && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ErrorMessage error={errorHandler.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </Paper>
    </Animate>
  );
};
