import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Page } from "components/shared/Page/Page";
import { RegisterForm } from "components/user/RegisterForm/RegisterForm";

interface Props {}

const useStyles = makeStyles(() => ({
  Register: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export const Register = (props: RouteComponentProps<Props>) => {
  const classes = useStyles();
  return (
    <Page animate className={[classes.Register]}>
      <RegisterForm />
    </Page>
  );
};
