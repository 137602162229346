import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { VideoPlayer } from "components/shared/VideoPlayer/VideoPlayer";
import { Maybe } from "graphql/generated/generated";

const useStyles = makeStyles({
  courseVideo: {
    background: "#eee",
    border: "1px solid #ddd",
    margin: "0 0 10px 0",
    height: "500px",
    width: "100%",
  },
});

interface Props {
  source?: Maybe<string>;
}
export const CourseVideo = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.courseVideo}>
      {props.source ? <VideoPlayer src={props.source} /> : null}
    </div>
  );
};
