import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SectionTitle } from "components/shared/SectionTitle/SectionTitle";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles({
  about: {
    borderRadius: 5,
    margin: "100px 0",
  },
  aboutContent: {
    marginTop: 20,
  },
});
interface Props {}

export const About: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.about}>
      <SectionTitle
        text="Rreth nesh"
        rightLinkTo="/about"
        rightLinkText="Shiko me shume"
      />
      <div className={classes.aboutContent}>
        <Typography variant="h6">
          Lorem ipsum dolor sit amet, consectet cumque dignissimos dolor dolores
          dolorum eaque expe
        </Typography>

        <Typography variant="h6">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga
          laudantium minima quam quis quod rerum! Lorem ipsum dolor sit amet,
          consectetur adipisicing elit. Accusantium aliquam aperiam beatae,
          consequuntur corporis cumque dignissimos dolor dolores dolorum eaque
          expedita illum iure libero maxime neque nisi non officia
        </Typography>
      </div>
    </div>
  );
};
