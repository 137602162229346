import { gql } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { fromPromise } from "@apollo/client";
import { MALUKI_USER_DETAILS } from "../../lib/context/AuthContext/AuthContextProvider";

export const REFRESH_JWT_AUTH_TOKEN = gql`
  mutation refreshJwtAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(
      input: {
        clientMutationId: "refresh-token-mutation"
        jwtRefreshToken: $refreshToken
      }
    ) {
      authToken
      clientMutationId
    }
  }
`;

const logout = () => {
  localStorage.removeItem(MALUKI_USER_DETAILS);
  window.location.reload();
};

// TODO: Refactor the use of getNewTokenQuery variable, to moved inside the function or a file
let getNewTokenQuery: Promise<any>;
let isTokenNewQueryResolving: Boolean = false;

export const getNewToken = async () => {
  const token = localStorage.getItem(MALUKI_USER_DETAILS);

  if (!token || !JSON.parse(token)?.jwtRefreshToken) {
    logout();
    return;
  }

  if (getNewTokenQuery && isTokenNewQueryResolving) {
    return getNewTokenQuery;
  }

  isTokenNewQueryResolving = true;

  try {
    const refreshToken = JSON.parse(token)?.jwtRefreshToken;
    const res = await fetch(
      "https://management.maluki.santea34.com/graphql" as RequestInfo,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          variables: {
            refreshToken: refreshToken,
          },
          query: REFRESH_JWT_AUTH_TOKEN.loc?.source.body,
        }),
      }
    );
    const resNew = await res.json();
    isTokenNewQueryResolving = false;
    return resNew.data.refreshJwtAuthToken.authToken;
  } catch (error) {
    console.error(error);
  }
  return getNewTokenQuery;
};

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    console.log("graphQLErrors", graphQLErrors);

    const token = localStorage.getItem(MALUKI_USER_DETAILS);

    if (!token || !JSON.parse(token)?.jwtRefreshToken) {
      // logout();
      return;
    }

    const authToken = JSON.parse(token);

    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        let errM = err as any;
        if (
          errM.debugMessage &&
          errM.debugMessage.indexOf("invalid-jwt") >= 0
        ) {
          return fromPromise(
            getNewToken().catch((e) => {
              logout();
              return;
            })
          ).flatMap((accessToken): any => {
            if (accessToken && accessToken.length > 0) {
              localStorage.setItem(
                MALUKI_USER_DETAILS,
                JSON.stringify({
                  jwtAuthToken: accessToken,
                  jwtRefreshToken: authToken.jwtRefreshToken,
                })
              );
              window.location.reload();
            }
            return forward(operation);
          });
        }
        if (err?.message === "You have been login to another device") {
          logout();
          return;
        }
      }
      // for (let err of graphQLErrors) {
      //   console.log("ERR:", err);
      //   const e = err as any;
      //
      //   if (
      //     e?.debugMessage ===
      //     "invalid-jwt | The iss do not match with this server"
      //   ) {
      //     let forward$;
      //
      //     if (!isRefreshing) {
      //       console.log("authToken.jwtRefreshToken", authToken);
      //       isRefreshing = true;
      //       forward$ = fromPromise(
      //         client
      //           .mutate({
      //             mutation: REFRESH_JWT_AUTH_TOKEN,
      //             variables: {
      //               refreshToken: authToken.jwtRefreshToken,
      //             },
      //           })
      //           .then(({ data: { refreshToken } }) => {
      //             console.log("success....", refreshToken);
      //             return true;
      //           })
      //           .then(() => {
      //             resolvePendingRequests();
      //             return true;
      //           })
      //           .catch((e) => {
      //             console.log("refresh token error", e);
      //             pendingRequests = [];
      //             return false;
      //           })
      //           .finally(() => {
      //             isRefreshing = false;
      //           })
      //       );
      //     } else {
      //       forward$ = fromPromise(
      //         new Promise((resolve) => {
      //           pendingRequests.push(() => resolve({}));
      //         })
      //       );
      //     }
      //
      //     return forward$.flatMap(() => forward(operation));
      //   }
      //
      //   if (err?.message === "You have been login to another device") {
      //     logout();
      //     return;
      //   }
      //
      //   console.log(
      //     `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
      //   );
      // }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (graphQLErrors) {
//       for (let err of graphQLErrors) {
//         console.log("err", err);
//
//         if (err?.message === "You have been login to another device") {
//           localStorage.removeItem(MALUKI_USER_DETAILS);
//           window.location.reload();
//           return;
//         }
//
//         // switch (err.extensions.code) {
//         //   case "UNAUTHENTICATED":
//         //     // error code is set to UNAUTHENTICATED
//         //     // when AuthenticationError thrown in resolver
//         //
//         //     return fromPromise(
//         //       getNewToken()
//         //         .then(({ accessToken, refreshToken }) => {
//         //           // Store the new tokens for your auth link
//         //           return accessToken;
//         //         })
//         //         .catch((error) => {
//         //           // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
//         //           return;
//         //         })
//         //     )
//         //       .filter((value) => Boolean(value))
//         //       .flatMap(() => {
//         //         // retry the request, returning the new observable
//         //         return forward(operation);
//         //       });
//         // }
//       }
//     }
//     if (networkError) {
//       console.log(`[Network error]: ${networkError}`);
//       // if you would also like to retry automatically on
//       // network errors, we recommend that you use
//       // apollo-link-retry
//     }
//   }
// );
