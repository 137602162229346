import React from "react";
import { Grid } from "@material-ui/core";
import { Alert } from "components/shared/Alert/Alert";

export const CourseNotFound = () => {
  return (
    <Grid item style={{ width: "100%" }}>
      <Alert severity="warning">Kursi nuk u gjet!</Alert>
    </Grid>
  );
};
