import React from "react";
import classNames from "classnames";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  LessonFragment,
  LessonIntroFragment,
} from "graphql/generated/generated";
import Image from "components/shared/Image/Image";
import { Alert } from "components/shared/Alert/Alert";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import useCourseRouter from "lib/hooks/useCourseRouter";

const activeColor = "#16a3e0";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    courseLessons: {},
    lesson: {
      display: "flex",
      alignItems: "flex-start",
      backgroundColor: "#f7f9fa",
      padding: 10,
      borderBottom: "1px solid #ddd",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#f3f5fa",
      },
      "&:last-child": {
        borderBottom: "none",
      },
    },
    lessonActive: {
      backgroundColor: activeColor,
      "& p": {
        color: "#fff",
      },
      "&:hover": {
        backgroundColor: activeColor,
      },
    },
    lessonDisabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      backgroundColor: "#f7f9fa !important",
    },
    lessonThumb: {
      minWidth: 50,
      maxWidth: 50,
      height: 50,
      border: "1px solid #eee",
      padding: 2,
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
    },
    lessonInfo: {
      marginLeft: 15,
    },
    lessonTitle: {
      fontSize: 15,
    },
    lessonDuration: {
      fontSize: 14,
      color: "#41494b",
    },
  })
);

type Lesson = LessonFragment | LessonIntroFragment;

interface Props {
  lessons: Lesson[];
  disabled?: boolean;
}

export const CourseLessons = (props: Props) => {
  const classes = useStyles();
  const authCtx = useAuthContext();
  const router = useCourseRouter();

  const handleOnLessonClick = (slug?: string | undefined) => {
    if (props.disabled || !slug) return;
    router.history.push(`/course/${router.match.params.courseSlug}/${slug}`);
  };

  const lessons = props.lessons || [];

  return (
    <div className={classes.courseLessons}>
      {!lessons || lessons.length === 0 ? (
        <Alert severity="info">
          Nuk ka mësime të disponueshme për momentin!
        </Alert>
      ) : (
        lessons.map((l: Lesson) => (
          <div
            role="button"
            onClick={() => handleOnLessonClick(l.slug as string | undefined)}
            key={l.databaseId}
            className={classNames({
              [classes.lesson]: true,
              [classes.lessonActive]: l.slug === router.match.params.lessonSlug,
              [classes.lessonDisabled]:
                props.disabled || !authCtx.isAuthenticated,
            })}
          >
            <div className={classes.lessonThumb}>
              <Image src={l.featuredImage?.node?.mediaItemUrl} />
            </div>
            <div className={classes.lessonInfo}>
              <Typography className={classes.lessonTitle}>{l.title}</Typography>
              <Typography className={classes.lessonDuration}>
                {l.lessonOptions?.duration}
              </Typography>
            </div>
          </div>
        ))
      )}
    </div>
  );
};
