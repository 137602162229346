import React from "react";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  slider: {
    height: 400,
    border: "1px solid #eee",
    "& .slick-slider, .slick-list, .slick-slide img": {
      height: "100%",
    },
    "& .slick-arrow": {
      background: "#c0c0c0",
      width: 35,
      height: 35,
      zIndex: 1,
    },
    "& .slick-next": {
      right: -35,
    },
    "& .slick-prev": {
      left: -35,
    },
  },
  sliderItem: {},
});

interface Props {}

export const Slider: React.FC<Props> = () => {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2100,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={classes.slider}>
      <Slick {...settings}>
        <div className={classes.sliderItem}>
          <img
            src="https://wowslider.net/local-sliders/demo-10/data1/images/idaho239691_1920.jpg"
            alt=""
          />
        </div>
        <div className={classes.sliderItem}>
          <img
            src="https://wowslider.net/local-sliders/demo-10/data1/images/road220058.jpg"
            alt=""
          />
        </div>
        <div className={classes.sliderItem}>
          <img
            src="https://wowslider.net/local-sliders/demo-10/data1/images/tuscany428041.jpg"
            alt=""
          />
        </div>
      </Slick>
    </div>
  );
};
