import React from "react";
import queryString from "query-string";
import { useRouter } from "../../lib/hooks/useRouter";
import SearchResultItems from "../../components/partials/Search/SearchResultItems";
import { Page } from "../../components/shared/Page/Page";

export const SearchResults = () => {
  const { location } = useRouter();
  const params = queryString.parse(location.search);
  const searchValue = params.query || "";

  const keyword =
    searchValue && Array.isArray(searchValue)
      ? searchValue.join()
      : searchValue;

  return (
    <Page animate>
      <SearchResultItems query={keyword} />
    </Page>
  );
};
