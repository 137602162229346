import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { ForumListItemFragment } from "graphql/generated/generated";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { useRouter } from "../../../lib/hooks/useRouter";

const useStyles = makeStyles({
  forumListItem: {
    padding: 30,
    marginBottom: 30,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",

    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  forumListItemReadOnly: {
    opacity: 0.4,
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: 20,
  },
  date: {
    color: "#6e6e6e",
  },
});
interface Props {
  forumItem: ForumListItemFragment;
  hideExcerpt?: boolean;
  readonly?: boolean;
}

export const ForumListItem = (props: Props) => {
  const { forumItem } = props;

  const classes = useStyles();
  const router = useRouter();

  const handleOnLessonClick = (slug?: string | null | undefined) => {
    if (props.readonly || !slug) return;
    router.history.push(`/forum/${slug}`);
  };

  return (
    <Paper
      onClick={() => handleOnLessonClick(forumItem.slug)}
      className={classNames({
        [classes.forumListItem]: true,
        [classes.forumListItemReadOnly]: props.readonly,
      })}
    >
      <div style={{ flex: "1", paddingRight: 10 }}>
        <Typography variant="h5" className={classes.title}>
          {forumItem.title}
        </Typography>

        {forumItem.date && (
          <Typography className={classes.date}>
            {format(new Date(forumItem.date), "dd/MM/yyyy  HH:ii")}
          </Typography>
        )}

        {!props.hideExcerpt && (
          <Typography>
            <span
              dangerouslySetInnerHTML={{
                __html: forumItem.excerpt?.trim() || "",
              }}
            />
          </Typography>
        )}
      </div>

      {forumItem.forumOptions?.resolved && (
        <div>
          <CheckCircle style={{ color: "#4fb848", fontSize: 40 }} />
        </div>
      )}
    </Paper>
  );
};
