import React from "react";
import { Page } from "components/shared/Page/Page";
import { ForumList } from "components/forum/ForumList";

export const Forum = () => {
  return (
    <Page animate>
      <ForumList />
    </Page>
  );
};
