import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/shared/Button/Button";
import { ArrowBack } from "@material-ui/icons";
import { useRouter } from "lib/hooks/useRouter";
import { Animate } from "components/shared/Animate/Animate";

const useStyles = makeStyles({
  page: {
    width: "100%",
    minHeight: "calc(100vh - 330px)",
    padding: "20px 0 20px 0",
  },
  backButton: {
    marginBottom: 20,
  },
});

interface Props {
  className?: string | string[];
  children: React.ReactNode;
  animate?: boolean;
  showBackButton?: boolean;
  backButtonText?: string;
  onGoBack?: () => void;
}
export const Page = (props: Props) => {
  const classes = useStyles();
  const router = useRouter();

  const handleOnGoBackClick = () => {
    if (props.onGoBack) {
      props.onGoBack();
      return;
    }

    router.history.goBack();
  };

  return (
    <Animate animate={props.animate ? "bottom-up" : undefined}>
      <div className={classNames([classes.page, props.className])}>
        {props.showBackButton && (
          <div className={classes.backButton}>
            <Button
              startIcon={<ArrowBack />}
              variant="outlined"
              onClick={handleOnGoBackClick}
            >
              {props.backButtonText || "Kthehu mbrapa"}
            </Button>
          </div>
        )}
        {props.children}
      </div>
    </Animate>
  );
};
