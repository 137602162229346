import React from "react";
import * as Yup from "yup";
import { TextField } from "components/shared/TextField/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { FormControlLabel, Switch } from "@material-ui/core";
import Button from "components/shared/Button/Button";
import {
  useCreateCommentMutation,
  useUpdateCommentAnonymousMutation,
} from "graphql/generated/generated";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { DocumentNode } from "graphql";

const useStyles = makeStyles({
  addComment: {
    marginTop: 50,
  },
  commentField: {
    width: "100%",
    minHeight: 150,
    backgroundColor: "#efefef",
  },
  switch: {},

  cancelComment: {
    marginRight: 10,
  },
  saveComment: {},
  errorMessage: {
    marginBottom: 10,
  },
  bottomWrapper: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
});

interface Props {
  parent?: number;
  commentOn?: number;
  slug: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  queryDocument: DocumentNode;
}

interface CommentFields {
  comment: string;
  anonymous: boolean;
  name: string | null;
  email: string | null;
}

const CreateComment = (props: Props) => {
  const classes = useStyles();
  const authCtx = useAuthContext();
  const errorHandler = useErrorHandler();
  const [createComment] = useCreateCommentMutation();
  const [
    updateCommentAnonymous,
    { loading: updatingComment },
  ] = useUpdateCommentAnonymousMutation();

  const queryToRefetch = {
    query: props.queryDocument,
    variables: {
      slug: props.slug,
    },
  };

  const formik = useFormik<CommentFields>({
    initialValues: {
      anonymous: false,
      comment: "",
      name: "",
      email: "",
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      comment: Yup.string().trim().required("Komenti eshte i domosdoshem"),
    }),
    onSubmit: async (values, formikHelpers) => {
      if (!props.commentOn) {
        return;
      }
      try {
        errorHandler.reset();

        const res = await createComment({
          variables: {
            parent: props.parent as any,
            commentOn: props.commentOn,
            content: values.comment,
            author: values.anonymous ? "Anonim" : authCtx.user?.name || "",
          },
        });

        const commentDatabaseId = res.data?.createComment?.comment?.databaseId;

        if (commentDatabaseId) {
          await updateCommentAnonymous({
            variables: {
              id: commentDatabaseId,
              isAnonymous: values.anonymous,
            },
            refetchQueries: [queryToRefetch],
          });
        }

        if (props.onSuccess) {
          props.onSuccess();
        }

        formikHelpers.resetForm();
      } catch (e) {
        errorHandler.handleError(e);
      }
    },
  });

  const { touched, values, errors } = formik;

  return (
    <div className={classes.addComment}>
      <form onSubmit={formik.handleSubmit}>
        <ErrorMessage
          error={errorHandler.error}
          showCloseButton
          className={classes.errorMessage}
          onClose={() => {
            formik.setErrors({});
            errorHandler.reset();
          }}
        />

        <TextField
          fullWidth
          multiline
          name="comment"
          label="Komenti"
          value={values.comment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          rows={6}
          rowsMax={8}
          error={touched.comment && !!errors.comment}
          helperText={touched.comment ? errors.comment : undefined}
        />

        <div className={classes.bottomWrapper}>
          <FormControlLabel
            className={classes.switch}
            control={
              <Switch
                checked={values.anonymous}
                onChange={(event) => {
                  formik.setFieldTouched("anonymous");
                  formik.setFieldValue("anonymous", event.target.checked);
                }}
                onBlur={formik.handleBlur}
                name="checkedB"
                color="primary"
              />
            }
            label="Anonim"
          />

          <span>
            {props.onCancel && (
              <Button
                type="submit"
                color="secondary"
                className={classes.cancelComment}
                onClick={props.onCancel}
              >
                Mbyll
              </Button>
            )}

            <Button
              type="submit"
              color="primary"
              className={classes.saveComment}
              disabled={
                formik.isSubmitting || !props.commentOn || updatingComment
              }
            >
              {formik.isSubmitting || updatingComment
                ? "Ju lutem prisni"
                : "Ruaj"}
            </Button>
          </span>
        </div>
      </form>
    </div>
  );
};

export default CreateComment;
