import React from "react";
import { Grid } from "@material-ui/core";
import { CourseVideo } from "components/course/ui/CourseVideo";
import { CourseLessons } from "components/course/ui/CourseLessons";
import { makeStyles } from "@material-ui/core/styles";
import { CourseCheckoutButton } from "components/course/ui/CourseCheckoutButton";
import { useGetCourseIntroBySlugQuery } from "graphql/generated/generated";
import { Loader } from "components/shared/Loader/Loader";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { LessonIntroFragment } from "graphql/generated/generated";
import { CourseInfo } from "components/course/ui/CourseInfo";
import { CourseNotFound } from "components/course/ui/CourseNotFound";

const useStyles = makeStyles({
  courseIntro: {
    width: "100%",
    height: "100%",
    marginBottom: 30,
  },
});

interface Props {
  courseSlug: string;
}

export const CourseIntro = (props: Props) => {
  const classes = useStyles();
  const { data, loading, error } = useGetCourseIntroBySlugQuery({
    variables: {
      slug: props.courseSlug,
    },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  const courseIntro = data?.courseBy;

  return (
    <div className={classes.courseIntro}>
      {!courseIntro ? (
        <CourseNotFound />
      ) : (
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} md={9}>
            <CourseVideo
              source={courseIntro?.courseOptions?.video?.mediaItemUrl}
            />
            <CourseInfo
              title={courseIntro?.title}
              content={courseIntro?.content}
            />
            <CourseCheckoutButton />
          </Grid>

          <Grid item xs={12} md={3}>
            <CourseLessons
              lessons={
                (courseIntro?.courseOptions?.lessons ||
                  []) as LessonIntroFragment[]
              }
              disabled
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
