import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Page } from "components/shared/Page/Page";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { CourseIntro } from "components/course/CourseIntro";
import { Course as CourseComponent } from "components/course/Course";
import { useRouter } from "lib/hooks/useRouter";

interface Props {
  courseSlug: string;
  lessonSlug?: string;
}

export const Course = (props: RouteComponentProps<Props>) => {
  const authCtx = useAuthContext();
  const router = useRouter();

  return (
    <Page animate showBackButton onGoBack={() => router.history.goBack()}>
      {!authCtx.isAuthenticated ? (
        <CourseIntro courseSlug={props.match.params.courseSlug} />
      ) : (
        <CourseComponent
          courseSlug={props.match.params.courseSlug}
          lessonSlug={props.match.params.lessonSlug}
        />
      )}
    </Page>
  );
};
