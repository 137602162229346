import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import "./Plyr.scss";

const useStyles = makeStyles({
  videoPlayer: {
    width: "100%",
    height: "100%",
    display: "flex",
    "& .plyr": {
      width: "100%",
      height: "100%",
    },
  },
});

interface Props {
  src: string;
}

export const VideoPlayer = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.videoPlayer}>
      <Plyr
        source={{
          type: "video",
          sources: [
            {
              src: props.src,
              provider: "html5",
            },
          ],
        }}
        options={{}}
      />
    </div>
  );
};
