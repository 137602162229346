import { setContext } from "@apollo/client/link/context";
import { MALUKI_USER_DETAILS } from "../../lib/context/AuthContext/AuthContextProvider";

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(MALUKI_USER_DETAILS);
  const defaultHeaders = { headers: { ...headers } };

  if (!token) {
    return defaultHeaders;
  }

  const authToken = JSON.parse(token);

  if (!authToken || !authToken.jwtAuthToken) {
    return defaultHeaders;
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${authToken.jwtAuthToken}`,
    },
  };
});
