import React from "react";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useDeleteCommentMutation } from "graphql/generated/generated";
import { useConfirmation } from "lib/context/ConfirmationContext/ConfirmationContext";
import { Loader } from "components/shared/Loader/Loader";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import { DocumentNode } from "graphql";

interface Props {
  id: string;
  forceDelete?: boolean;
  slug: string;
  queryDocument: DocumentNode;
}

export const DeleteComment = (props: Props) => {
  const confirmation = useConfirmation();
  const snackbar = useSnackbar();
  const [deleteComment, { loading }] = useDeleteCommentMutation();

  const handleDelete = async () => {
    confirmation
      .confirm()
      .then(async () => {
        try {
          await deleteComment({
            variables: {
              id: props.id,
              forceDelete: props.forceDelete,
            },
            refetchQueries: [
              {
                query: props.queryDocument,
                variables: {
                  slug: props.slug,
                },
              },
            ],
          });
        } catch (e) {
          snackbar.error(e.message || "Diqka shkoi gabim");
        }
      })
      .catch(() => {});
  };

  return (
    <IconButton color="secondary" onClick={handleDelete} disabled={loading}>
      {!loading ? <Delete /> : <Loader size={20} />}
    </IconButton>
  );
};
