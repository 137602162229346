import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, CircularProgressProps } from "@material-ui/core";

const useStyles = makeStyles({
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
});

interface Props {
  className?: string;
  size?: CircularProgressProps["size"];
}

export const Loader = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames([classes.loader, props.className])}>
      <CircularProgress size={props.size} />
    </div>
  );
};
