import React from "react";
import queryString from "query-string";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import Button from "components/shared/Button/Button";
import { useSnackbar } from "lib/context/SnackbarContext/SnackbarContext";
import { useRouter } from "lib/hooks/useRouter";
import { useAccountVerificationMutation } from "graphql/generated/generated";

const useStyles = makeStyles({
  accountVerificationForm: {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },

  paper: {
    padding: "30px",
  },

  accountVerificationWithEmail: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export const AccountVerificationForm = () => {
  const classes = useStyles();
  const errorHandler = useErrorHandler();
  const snackbar = useSnackbar();
  const router = useRouter<any>();
  const [accountVerification] = useAccountVerificationMutation();

  const [loading, setLoading] = React.useState(false);

  const params = queryString.parse(router.location.search);

  const handleVerify = async () => {
    if (!params || !params.userId || !params.act) return;
    const userId = parseInt(params.userId as string);
    if (!userId) return;

    try {
      errorHandler.reset();
      setLoading(true);
      await accountVerification({
        variables: {
          userId: userId,
          key: params.act as string,
        },
      });
      snackbar.info("Llogaria është verifikuar me sukses");
      router.history.push("/login");
    } catch (e) {
      errorHandler.handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.accountVerificationForm}>
      <Paper className={classes.paper}>
        <ErrorMessage
          showCloseButton
          error={errorHandler.error}
          alertProps={{ style: { marginBottom: 15, width: "100%" } }}
          onClose={() => {
            errorHandler.reset();
          }}
        />

        <div className={classes.accountVerificationWithEmail}>
          <Button
            disabled={loading}
            color="primary"
            style={{ width: "100%" }}
            onClick={handleVerify}
          >
            {loading ? "Ju lutem prisni" : "Verifiko llogarine"}
          </Button>
        </div>
      </Paper>
    </div>
  );
};
