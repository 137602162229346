import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Lesson as LessonSingle } from "components/lessons/Lesson";
import { Page } from "components/shared/Page/Page";

interface Props {
  lessonSlug?: string;
}

export const Lesson: React.FC<RouteComponentProps<Props>> = (props) => {
  return (
    <Page animate showBackButton onGoBack={() => props.history.goBack()}>
      <LessonSingle lessonSlug={props.match.params.lessonSlug} />
    </Page>
  );
};
