import { ApolloClient, ApolloLink } from "@apollo/client";
import { cache } from "./cache";
import { errorLink } from "./links/errorLink";
import { authLink } from "./links/authLink";
import { httpLink } from "./links/httpLink";

export const client = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([authLink, errorLink, httpLink]),
});
