import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { DefaultPage } from "pages/DefaultPage/DefaultPage";
import { Page } from "components/shared/Page/Page";

interface Props {}

export const PrivacyPolicy: React.FC<RouteComponentProps<Props>> = (props) => {
  return (
    <Page animate>
      <DefaultPage
        title="Politikat e privatësisë"
        content="<div> <p> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad aperiam asperiores dignissimos enim, excepturi exercitationem iste maxime nam quaerat rem tenetur? Alias consequuntur delectus iusto quidem rerum sequi sit. Atque aut blanditiis corporis culpa dolorem doloremque ea enim excepturi facilis fugiat hic ipsam, ipsum iusto magni molestiae neque nostrum odit placeat quas reiciendis repellat reprehenderit sed tempore, temporibus tenetur! Exercitationem ipsam nihil obcaecati, omnis vitae voluptates voluptatibus.</p><p> Amet aperiam, consectetur dicta doloribus ipsa iure laboriosam nulla officia pariatur, quae quos, reiciendis saepe sunt suscipit unde! Dolorem, harum illum maiores modi quasi sed temporibus. Dicta dolor enim ex id totam.</p></div>"
      />
    </Page>
  );
};
