import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface ForgotPasswordFields {
  email: string;
}

interface UseForgotPasswordFormOptions {
  initialValues: ForgotPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ForgotPasswordFields>
  ) => Promise<any>;
}

export const useForgotPasswordFormik = (
  options: UseForgotPasswordFormOptions
) => {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required("Kjo fushë është e nevojshme"),
  });

  return useFormik({
    initialValues: {
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ForgotPasswordFormik = ReturnType<typeof useForgotPasswordFormik>;
