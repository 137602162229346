import React from "react";
import {
  CommentFragment,
  GetLessonCommentsDocument,
  useGetLessonCommentsQuery,
} from "graphql/generated/generated";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { Comments } from "components/comments/Comments";
import CreateComment from "components/comments/CreateComment";

interface Props {
  lessonId?: number;
  slug: string;
}
export const LessonComments = (props: Props) => {
  const { data, error } = useGetLessonCommentsQuery({
    variables: {
      slug: props.slug,
    },
  });

  if (error) return <ErrorMessage error={error} />;

  if (!props.lessonId) return null;

  const comments = (data?.lessonBy?.comments?.nodes ?? []) as CommentFragment[];

  return (
    <>
      <Comments queryDocument={GetLessonCommentsDocument} comments={comments} />

      <CreateComment
        queryDocument={GetLessonCommentsDocument}
        slug={props.slug}
        commentOn={props.lessonId}
      />
    </>
  );
};
