import React from "react";
import { useQueryParameters } from "lib/hooks/useQueryParameters";
import {
  RootQueryToLessonConnectionWhereArgsTaxQueryOperator,
  useGetLessonsByCategoryQuery,
} from "graphql/generated/generated";
import { LessonBox } from "components/lessons/ui/LessonBox";
import { Grid } from "@material-ui/core";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";

interface Props {}

export const LessonsByCategory: React.FC<Props> = () => {
  const params = useQueryParameters();
  const category = params.get("category");

  const { data, loading, error } = useGetLessonsByCategoryQuery({
    variables: {
      categorySlug: category,
      operator: !!category
        ? RootQueryToLessonConnectionWhereArgsTaxQueryOperator["In"]
        : RootQueryToLessonConnectionWhereArgsTaxQueryOperator["NotIn"],
    },
  });

  if (error) return <ErrorMessage error={error} />;

  const lessons = data?.lessons?.nodes ?? [];

  return (
    <Grid container spacing={3}>
      {(loading ? Array.from(new Array(6)) : lessons).map((lesson, index) => {
        return (
          <Grid
            item
            xs={6}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={`${lesson?.databaseId}-${index}`}
          >
            <LessonBox item={lesson} />
          </Grid>
        );
      })}
    </Grid>
  );
};
