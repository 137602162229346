import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { HtmlContent } from "components/shared/HTMLContent/HTMLContent";

const useStyles = makeStyles({
  defaultPage: {
    "& .HTMLContent p ": {
      marginBottom: 10,
      lineHeight: "1.2em",
    },
  },
});

interface Props {
  title: string;
  content?: string;
}

export const DefaultPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.defaultPage}>
      <Typography
        style={{ marginBottom: 30, textAlign: "center" }}
        variant="h5"
      >
        {props.title}
      </Typography>
      <HtmlContent content={props.content} />
    </div>
  );
};
