import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface LoginFields {
  email: string | null;
  password: string | null;
}

interface UseLoginFormOptions {
  initialValues?: LoginFields;
  onSubmit: (
    values: LoginFields,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().required("Kjo fushë është e nevojshme"),
  password: Yup.string()
    .min(6, "Fjalëkalimi duhet të jetë më shumë se 6 shkronja")
    .required("Kjo fushë është e nevojshme"),
});

export const useLoginFormik = (options: UseLoginFormOptions) => {
  return useFormik<LoginFields>({
    initialValues: {
      email: "",
      password: "",
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type LoginFormik = ReturnType<typeof useLoginFormik>;
