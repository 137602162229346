import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Skeleton from "@material-ui/lab/Skeleton";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Lesson } from "graphql/generated/generated";
import placeholder from "assets/images/placeholder.jpg";
import { useRouter } from "lib/hooks/useRouter";

interface Props {
  loading?: boolean;
  item?: Lesson;
}

const useStyles = makeStyles({
  root: {
    padding: 0,
    height: "100%",
    boxShadow: "none",
    border: "1px solid #eee",
  },

  cardActionArea: {
    height: "100%",
  },

  media: {
    height: 200,
    backgroundSize: "97%",
    backgroundPosition: "center center",
  },

  lessonsLength: {
    color: "#6a6f73",
    fontSize: 14,
  },
  lessonInfo: {
    padding: "10px 15px",
  },
});

export const LessonBox = (props: Props) => {
  const { item } = props;
  const classes = useStyles();
  const router = useRouter();

  return (
    <Card component="div" className={classes.root}>
      <CardActionArea
        component="div"
        className={classes.cardActionArea}
        onClick={() => router.history.push(`/lesson/${item?.slug}`)}
      >
        {item ? (
          <CardMedia
            className={classes.media}
            image={item?.featuredImage?.node?.mediaItemUrl || placeholder}
            title="Contemplative Reptile"
          />
        ) : (
          <Skeleton variant="rect" height={200} />
        )}

        <CardContent className={classes.lessonInfo}>
          {item ? (
            <Typography>
              <strong>{item.title}</strong>
            </Typography>
          ) : (
            <Skeleton height={30} />
          )}

          {item ? (
            <Typography>Kohëzgjatja: {item.lessonOptions?.duration}</Typography>
          ) : (
            <Skeleton width="50%" height={20} />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
