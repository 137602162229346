import React from "react";
import { format } from "date-fns";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CommentFragment,
  GetForumBySlugDocument,
  MediaItem,
  useGetForumBySlugQuery,
} from "graphql/generated/generated";
import { Loader } from "components/shared/Loader/Loader";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { ForumSingleComments } from "./ui/ForumSingleComments";
import CreateComment from "components/comments/CreateComment";
import { CourseFiles } from "components/course/ui/CourseFiles";
import { HtmlContent } from "components/shared/HTMLContent/HTMLContent";

const useStyles = makeStyles({
  forumSingleContent: {
    marginTop: 80,
  },
  title: {
    marginBottom: 25,
  },
  loader: {
    marginTop: 80,
  },
  date: {
    color: "#6e6e6e",
  },
  content: {
    marginTop: 10,
  },
  forumFiles: {
    margin: "20px 0",
  },
});

interface Props {
  slug: string;
}

export const ForumSingleContent = (props: Props) => {
  const classes = useStyles();

  const { loading, error, data } = useGetForumBySlugQuery({
    variables: {
      slug: props.slug,
    },
  });

  if (loading) return <Loader className={classes.loader} />;

  if (error) return <ErrorMessage error={error} />;

  const forumItem = data?.forumBy || null;

  const forumFiles = forumItem?.forumOptions?.files?.map((f) => f?.file);

  return (
    <div className={classes.forumSingleContent}>
      {forumItem ? (
        <>
          <Typography variant="h4" className={classes.title}>
            {forumItem.title}
          </Typography>

          {forumItem.date && (
            <Typography className={classes.date}>
              <strong>Autor: {forumItem?.author?.node?.name}</strong>,
              <span>
                {format(new Date(forumItem.date), "dd/MM/yyyy  HH:ii")}
              </span>
            </Typography>
          )}

          <Typography className={classes.content}>
            <HtmlContent content={forumItem.content} />
          </Typography>

          {forumFiles && (
            <div className={classes.forumFiles}>
              <CourseFiles
                title="Dosjet e forumit:"
                files={forumFiles as MediaItem[]}
              />
            </div>
          )}

          <ForumSingleComments
            comments={(forumItem.comments?.nodes || []) as CommentFragment[]}
          />

          <CreateComment
            queryDocument={GetForumBySlugDocument}
            commentOn={forumItem.databaseId}
            slug={props.slug}
          />
        </>
      ) : (
        <p>Ky post nuk u gjet!</p>
      )}
    </div>
  );
};
