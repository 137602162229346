import React from "react";
import Button from "components/shared/Button/Button";
import { makeStyles } from "@material-ui/core/styles";
import { ShoppingCart } from "@material-ui/icons";

const useStyles = makeStyles({
  courseCheckoutButton: {
    borderRadius: 0,
    padding: 13,
    maxWidth: 300,
    width: "100%",
    fontWeight: 600,
    marginTop: 20,
  },
});
export const CourseCheckoutButton = () => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      to="/register"
      className={classes.courseCheckoutButton}
      endIcon={<ShoppingCart />}
    >
      Abonohu
    </Button>
  );
};
