import React, { useState } from "react";
import Button from "components/shared/Button/Button";
import { Icon } from "components/shared/Icon/Icon";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { makeStyles } from "@material-ui/core/styles";
import { useLoginWithGoogleMutation } from "graphql/generated/generated";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import { useErrorHandler } from "lib/hooks/useErrorHandler";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";

const useStyles = makeStyles({
  loginWithGoogle: {
    width: "100%",
    marginTop: 20,
    paddingTop: 20,
    borderTop: "1px solid #eee",
    "& .MuiButtonBase-root": {
      width: "100%",
      padding: 9,
      textAlign: "center",
    },
    "& .Icon": {
      marginRight: 10,
    },
  },
});

interface Props {}

export const SocialMediaLogin: React.FC<Props> = () => {
  const classes = useStyles();
  const [, setPopUp] = useState(false);

  const errorHandler = useErrorHandler();
  const authCtx = useAuthContext();
  const [loginWithGoogle, { loading }] = useLoginWithGoogleMutation();

  const handleOnRequest = () => {
    setPopUp(true);
  };

  const handleOnSuccess = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const token = response.code;

    if (!token) {
      setPopUp(false);
      return;
    }

    try {
      const res = await loginWithGoogle({
        variables: {
          token: token,
        },
      });

      if (res.data?.googleLogin?.user) {
        authCtx.login(res.data?.googleLogin.user);
      }
    } catch (e) {
      errorHandler.handleError(e);
    }
  };

  const handleOnFailure = (error: any) => {
    console.error("error", error);
  };

  return (
    <div className={classes.loginWithGoogle}>
      {errorHandler.error && (
        <div style={{ margin: "10px 0" }}>
          <ErrorMessage error={errorHandler} onClose={errorHandler.reset} />
        </div>
      )}

      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        responseType="code"
        render={(renderProps) => (
          <Button
            disabled={loading}
            color="default"
            onClick={renderProps.onClick}
          >
            <Icon icon="google" /> <span>Kyqu me Google</span>
          </Button>
        )}
        onRequest={handleOnRequest}
        onSuccess={handleOnSuccess}
        onFailure={handleOnFailure}
        uxMode="popup"
      />
    </div>
  );
};
