import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronRight } from "@material-ui/icons";

const useStyles = makeStyles({
  sectionTitle: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #000",
  },
  title: {
    textTransform: "uppercase",
    letterSpacing: 1,
    color: "#000",
    fontSize: 18,
  },
  link: {
    letterSpacing: 1,
    color: "#000",
    fontSize: 15,
  },
});

interface Props {
  text: string;
  rightLinkText?: string;
  rightLinkTo?: string;
}

export const SectionTitle: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionTitle}>
      <Typography className={classes.title}>{props.text}</Typography>
      {props.rightLinkText && props.rightLinkTo && (
        <Link className={classes.link} to={props.rightLinkTo}>
          <Typography style={{ display: "flex" }}>
            {props.rightLinkText}
            <ChevronRight />
          </Typography>
        </Link>
      )}
    </div>
  );
};
