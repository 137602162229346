import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Person, Lock, ListAlt, PersonAddDisabled } from "@material-ui/icons";
import { Route } from "react-router-dom";
import { useRouter } from "lib/hooks/useRouter";
import { ProfileSidebar } from "components/user/Profile/ui/ProfileSidebar";
import { ProfileAccount } from "components/user/Profile/ui/ProfileAccount";
import { ProfileChangePassword } from "components/user/Profile/ui/ProfileChangePassword";
import { ProfileDeleteAccount } from "components/user/Profile/ui/ProfileDeleteAccount";
import { ProfileSubscriptionList } from "components/user/Profile/ui/ProfileSubscriptionList";

export const profileRoutes = [
  {
    title: "Llogaria ime",
    icon: Person,
    path: "account",
    component: ProfileAccount,
  },
  {
    title: "Ndrysho fjalëkalimin",
    icon: Lock,
    path: "change-password",
    component: ProfileChangePassword,
  },
  {
    title: "Çaktivizo llogarinë",
    icon: PersonAddDisabled,
    path: "delete-account",
    component: ProfileDeleteAccount,
  },
  {
    title: "Lista e abonimeve",
    icon: ListAlt,
    path: "subscription-list",
    component: ProfileSubscriptionList,
  },
];

export const ProfileContent = () => {
  const router = useRouter();

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        <ProfileSidebar />
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <Switch>
          {profileRoutes.map((x, i) => {
            return (
              <Route
                key={`${x.title}-${i}`}
                path={`${router.match.url}/${x.path}`}
                component={x.component}
              />
            );
          })}
          <Route render={() => <Redirect to="/profile/account" />} />
        </Switch>
      </Grid>
    </Grid>
  );
};
