import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  Hidden,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import MobileDrawer from "components/partials/Header/ui/MobileDrawer";
import { UserDropdown } from "components/partials/Header/ui/UserDropdown";
import { Search } from "components/partials/Search/Search";
import { useAuthContext } from "lib/context/AuthContext/AuthContext";
import Button from "components/shared/Button/Button";
import Container from "components/shared/Container/Container";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      backgroundColor: "#fff",
      padding: 0,
    },
    logo: {
      width: 130,
    },
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    navList: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: 30,
    },
    navLink: {
      color: theme.palette.grey.A700,
      "&:hover": {
        color: theme.palette.grey.A700,
      },
    },
    navLinkActive: {
      color: "black",
    },
    actions: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const navLinks = [
  {
    title: "Kategoritë",
    path: "/lessons",
    exact: true,
    private: true,
  },
  { title: "Kurset", path: "/courses", exact: true },
  { title: "Forumi", path: "/forum", private: true },
];

const Header = () => {
  const classes = useStyles();
  const authCtx = useAuthContext();

  const filteredLinks = authCtx.isAuthenticated
    ? navLinks
    : navLinks.filter((x) => !x.private);

  return (
    <AppBar position="fixed" color="default" className={classes.header}>
      <Toolbar disableGutters>
        <Container className={classes.container}>
          <Link to="/">
            <Logo className={classes.logo} />
          </Link>

          <Hidden smDown>
            <List
              component="nav"
              aria-labelledby="main navigation"
              className={classes.navList}
            >
              {filteredLinks.map((item) => {
                return (
                  <NavLink
                    exact={item.exact}
                    to={item.path}
                    key={item.title}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                  >
                    <ListItem button>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>

            <div className={classes.actions}>
              <Search />

              {authCtx.isAuthenticated ? (
                <UserDropdown />
              ) : (
                <>
                  <Button variant="text" to="/login">
                    Kyqu
                  </Button>
                  <span style={{ margin: "0 5px" }}>|</span>
                  <Button variant="text" to="/register">
                    Regjistrohu
                  </Button>
                </>
              )}
            </div>
          </Hidden>

          <Hidden mdUp>
            <MobileDrawer navLinks={filteredLinks} />
          </Hidden>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
