import { useRouter } from "lib/hooks/useRouter";

type CourseRouteParams = {
  courseSlug: string;
  lessonSlug?: string;
};

const useCourseRouter = () => useRouter<CourseRouteParams>();

export default useCourseRouter;
