import React from "react";
import { Page } from "components/shared/Page/Page";
import { CoursesList } from "components/course/CoursesList";

export const Courses = () => {
  return (
    <Page animate>
      <CoursesList />
    </Page>
  );
};
