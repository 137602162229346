import React from "react";
import { Alert } from "../../shared/Alert/Alert";
import { useSearchQuery } from "../../../graphql/generated/generated";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { LessonBox } from "../../lessons/ui/LessonBox";
import { ForumListItem } from "../../forum/ui/ForumListItem";
import { SectionTitle } from "../../shared/SectionTitle/SectionTitle";
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

const useStyles = makeStyles({
  heading: {
    textAlign: "center",
    marginBottom: 50,
  },
});

interface Props {
  query: string;
}

const SearchResultItems = (props: Props) => {
  const classes = useStyles();
  const authCtx = useAuthContext();

  const { data, loading, error } = useSearchQuery({
    variables: {
      query: props.query,
    },
  });

  const lessons = data?.lessons?.nodes ?? [];
  const forums = data?.forums?.nodes ?? [];

  return (
    <Grid container spacing={3}>
      <Grid item style={{ width: "100%" }}>
        <Typography variant="h6" className={classes.heading}>
          Rezultatet e kërkimit per <strong>"{props.query}"</strong>
        </Typography>
      </Grid>

      {error ? (
        <Grid item style={{ width: "100%" }}>
          <Alert severity="error">
            Dicka shkoi gabim. Ju lutem provoni përsëri.
          </Alert>
        </Grid>
      ) : (
        <>
          {!loading && lessons.length === 0 && forums.length === 0 && (
            <Grid item style={{ width: "100%" }}>
              <Alert color="info">Asnjë rezultat nuk u gjet</Alert>
            </Grid>
          )}

          {lessons.length > 0 && (
            <Grid item style={{ width: "100%" }}>
              <SectionTitle
                text="Mësime"
                rightLinkTo="/lessons"
                rightLinkText="Shiko më shumë"
              />
            </Grid>
          )}

          {(loading ? Array.from(new Array(6)) : lessons).map(
            (lesson, index) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={`${lesson?.databaseId}-${index}`}
                >
                  <LessonBox item={lesson} />
                </Grid>
              );
            }
          )}

          {forums.length > 0 && (
            <Grid item style={{ width: "100%", marginTop: 60 }}>
              <SectionTitle
                text="Forum"
                rightLinkTo="/forum"
                rightLinkText="Shiko më shumë"
              />
            </Grid>
          )}

          {forums.map((forumItem) =>
            forumItem ? (
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4} key={forumItem.id}>
                <ForumListItem
                  forumItem={forumItem}
                  hideExcerpt
                  readonly={!authCtx.isAuthenticated}
                />
              </Grid>
            ) : null
          )}
        </>
      )}
    </Grid>
  );
};

export default SearchResultItems;
