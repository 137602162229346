import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Maybe } from "graphql/generated/generated";

const useStyles = makeStyles({
  courseInfo: {
    margin: "15px 0",
  },
  courseTitle: {
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 25,
    color: "#222",
  },
});

interface Props {
  title?: Maybe<string>;
  content?: Maybe<string>;
}
export const CourseInfo = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.courseInfo}>
      <Typography variant="h5" className={classes.courseTitle}>
        {props.title}
      </Typography>
      <Typography dangerouslySetInnerHTML={{ __html: props.content || "" }} />
    </div>
  );
};
