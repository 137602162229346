import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  CourseListItemFragment,
  useGetCoursesQuery,
} from "graphql/generated/generated";
import { CourseListItem } from "components/course/ui/CourseListItem";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";

export const CoursesList = () => {
  const { data, loading, error } = useGetCoursesQuery();

  if (error) return <ErrorMessage error={error} />;

  const courses = (data?.courses?.nodes || []) as CourseListItemFragment[];

  return (
    <Grid container spacing={4}>
      {(loading ? Array.from(new Array(6)) : courses)?.map(
        (item: CourseListItemFragment, index: number) => {
          return (
            <CourseListItem
              key={`course-list-item-${index}`}
              loading={loading}
              item={item}
            />
          );
        }
      )}
    </Grid>
  );
};
