import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { CourseVideo } from "components/course/ui/CourseVideo";
import { CourseFiles } from "components/course/ui/CourseFiles";
import {
  MediaItem,
  useGetLessonBySlugQuery,
} from "graphql/generated/generated";
import { Loader } from "components/shared/Loader/Loader";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { CourseInfo } from "components/course/ui/CourseInfo";
import { LessonComments } from "components/course/ui/LessonComments";
import { LessonNotFound } from "components/lessons/ui/LessonNotFound";

const useStyles = makeStyles({
  lesson: {
    width: "100%",
    height: "100%",
  },
});

interface Props {
  lessonSlug?: string;
}

export const Lesson = (props: Props) => {
  const classes = useStyles();

  const { data, loading, error } = useGetLessonBySlugQuery({
    fetchPolicy: "no-cache",
    variables: {
      slug: props.lessonSlug ?? "",
    },
  });

  if (loading) return <Loader />;

  if (error) return <ErrorMessage error={error} />;

  const lesson = data?.lessonBy;
  const lessonFiles = lesson?.lessonOptions?.files?.map((f) => f?.file);
  const lessonVideoLink = lesson?.lessonOptions?.video?.mediaItemUrl;

  return (
    <div className={classes.lesson}>
      <Grid container alignItems="flex-start">
        {!lesson ? (
          <LessonNotFound />
        ) : (
          <div style={{ width: "100%" }}>
            <CourseVideo source={lessonVideoLink} />

            <CourseInfo title={lesson?.title} content={lesson?.content} />

            <CourseFiles
              title="Dosjet e mësimit:"
              files={lessonFiles as MediaItem[]}
            />

            {lesson && lesson.slug && lesson.databaseId && (
              <LessonComments
                slug={lesson.slug}
                lessonId={lesson?.databaseId}
              />
            )}
          </div>
        )}
      </Grid>
    </div>
  );
};
