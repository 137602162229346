import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  CourseListItemFragment,
  useGetHomeCoursesQuery,
} from "graphql/generated/generated";
import { CourseListItem } from "components/course/ui/CourseListItem";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
import { makeStyles } from "@material-ui/core/styles";
import { SectionTitle } from "components/shared/SectionTitle/SectionTitle";

const useStyles = makeStyles({
  homeCourses: {
    marginTop: 100,
    marginBottom: 50,
  },
});

export const Courses = () => {
  const classes = useStyles();
  const { data, loading, error } = useGetHomeCoursesQuery();

  if (error) return <ErrorMessage error={error} />;

  const courses = (data?.courses?.nodes || []) as CourseListItemFragment[];

  return (
    <Grid container spacing={3} className={classes.homeCourses}>
      <Grid item style={{ width: "100%" }}>
        <SectionTitle
          text="Kurset"
          rightLinkText="Shiko te gjitha"
          rightLinkTo="/courses"
        />
      </Grid>
      {(loading ? Array.from(new Array(6)) : courses)?.map(
        (item: CourseListItemFragment, index: number) => {
          return (
            <CourseListItem
              key={`course-list-item-${index}`}
              loading={loading}
              item={item}
            />
          );
        }
      )}
    </Grid>
  );
};
