import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { UIContextProvider } from "lib/context/UIContext/UIContextProvider";
import { AuthContextProvider } from "lib/context/AuthContext/AuthContextProvider";
import { ConfirmationContextProvider } from "lib/context/ConfirmationContext/ConfirmationContextProvider";
import { SnackbarContextProvider } from "lib/context/SnackbarContext/SnackbarContext";
import { useScrollToTop } from "lib/hooks/useScrollToTop";

import Header from "components/partials/Header/Header";
import Footer from "components/partials/Footer/Footer";
import Container from "components/shared/Container/Container";
import { Routes } from "routes/Routes";

const useStyles = makeStyles({
  App: {
    paddingTop: 100,
  },
});

function App() {
  useScrollToTop();
  const classes = useStyles();

  return (
    <div className={classes.App}>
      <SnackbarContextProvider>
        <AuthContextProvider>
          <UIContextProvider>
            <ConfirmationContextProvider>
              <Header />
              <Container>
                <Routes />
              </Container>
              <Footer />
            </ConfirmationContextProvider>
          </UIContextProvider>
        </AuthContextProvider>
      </SnackbarContextProvider>
    </div>
  );
}

export default App;
