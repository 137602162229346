import React from "react";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  filePicker: {
    padding: 15,
    border: "1px solid #c4c4c4",
    borderRadius: 5,
  },
  filePickerDropZone: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: 5,
    border: "1px dashed #c4c4c4",
    backgroundColor: "#fafafa",
    cursor: "pointer",
    "&:focus": {
      outline: "none",
      border: "1px dashed #c4c4c4",
    },
    "& p": {
      color: "#c4c4c4",
    },
  },
  filePickerDropZoneError: {
    borderColor: "#f44336",
  },
  filePickerFiles: {
    marginTop: 10,
    "&:ul li": {
      listStyle: "none",
      paddingLeft: 10,
    },
  },
});

interface Props {
  className?: string;
  onSelect?: (selectedFiles: any) => void;
}

const FilePicker = (props: Props) => {
  const classes = useStyles();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxSize: 3145728, // 3MB
    onDrop: async (acceptedFiles1) => {
      props.onSelect?.(acceptedFiles1);
    },
  });

  const files = acceptedFiles.map((file: any) => {
    return (
      <li key={file.path}>
        <Typography>
          {file.path} - {file.size} bytes
        </Typography>
      </li>
    );
  });

  return (
    <div className={classNames([classes.filePicker, props.className])}>
      <div {...getRootProps({ className: classes.filePickerDropZone })}>
        <input {...getInputProps()} />
        <Typography>
          Tërhiqni apo hidhni skedarët këtu, ose klikoni për të zgjedhur
          skedarët
        </Typography>
      </div>
      {files && files.length > 0 && (
        <div className={classes.filePickerFiles}>
          <Typography>Skedarët:</Typography>
          <ul>{files}</ul>
        </div>
      )}
    </div>
  );
};

export default FilePicker;
