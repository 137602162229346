import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Page } from "components/shared/Page/Page";
import { makeStyles } from "@material-ui/core/styles";
import { AccountVerificationForm } from "components/user/AccountVerificationForm/AccountVerificationForm";

const useStyles = makeStyles(() => ({
  resetPassword: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

interface Props {}

export const AccountVerification = (props: RouteComponentProps<Props>) => {
  const classes = useStyles();
  return (
    <Page animate className={classes.resetPassword}>
      <AccountVerificationForm />
    </Page>
  );
};
