import React from "react";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useGetLessonCategoriesQuery } from "graphql/generated/generated";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import { useQueryParameters } from "lib/hooks/useQueryParameters";
import Skeleton from "@material-ui/lab/Skeleton";
import { ErrorMessage } from "components/shared/ErrorMessage/ErrorMessage";
// import { RedirectToFirstCategory } from "components/lessons/lib/RedirectToFirstCategory";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      borderRadius: 4,
      "& .MuiButtonBase-root": {
        margin: "0 0 10px 0",
        backgroundColor: "#eee",
      },
      "& .MuiListItemIcon-root": {
        minWidth: 40,
      },
      "& a": {
        color: "#000",
      },
    },
    title: {
      fontSize: 18,
      letterSpacing: 1.2,
      textTransform: "uppercase",
      borderBottom: "1px solid #000",
      marginBottom: 15,
    },
    selectedCategory: {
      "& .MuiButtonBase-root": {
        background: theme.palette.info.dark,
        color: "#fff",
      },
    },
  })
);
interface Props {}

export const LessonsCategories: React.FC<Props> = () => {
  const classes = useStyles();
  const params = useQueryParameters();
  const category = params.get("category");

  const { data, loading, error } = useGetLessonCategoriesQuery();

  if (error) return <ErrorMessage error={error} />;

  const categories = data?.kategorite?.nodes ?? [];

  return (
    <div className={classes.root}>
      {/*{!loading && !error && !category && (*/}
      {/*  <RedirectToFirstCategory categories={categories as Kategoria[]} />*/}
      {/*)}*/}

      {loading ? (
        <Skeleton height={50} style={{ marginBottom: 10 }} />
      ) : (
        <Typography className={classes.title}>Kategoritë</Typography>
      )}

      <List component="nav" aria-label="main mailbox folders">
        {!loading && (
          <NavLink
            className={classNames({
              [classes.selectedCategory]: !category,
            })}
            to={`/lessons`}
          >
            <ListItem button>
              <ListItemText primary="Të gjitha" />
            </ListItem>
          </NavLink>
        )}
        {(loading ? Array.from(new Array(4)) : categories).map((x, i) => {
          return (
            <span key={`${x?.databaseId}-${i}`}>
              {x ? (
                <NavLink
                  className={classNames({
                    [classes.selectedCategory]: category === x?.slug,
                  })}
                  to={`/lessons?category=${x?.slug}`}
                >
                  <ListItem button>
                    <ListItemText primary={x?.name} />
                  </ListItem>
                </NavLink>
              ) : (
                <Skeleton
                  variant="rect"
                  height={50}
                  style={{ marginBottom: 10 }}
                />
              )}
            </span>
          );
        })}
      </List>
    </div>
  );
};
