import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";

export interface ResetPasswordFields {
  key: string;
  login: string;
  password: string;
  passwordConfirm: string;
}

interface UseResetPasswordFormOptions {
  initialValues: ResetPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ResetPasswordFields>
  ) => Promise<any>;
}

export const useResetPasswordFormik = (
  options: UseResetPasswordFormOptions
) => {
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Kjo fushë është e nevojshme"),
    passwordConfirm: Yup.string()
      .required("Kjo fushë është e nevojshme")
      .oneOf([Yup.ref("password")], "Fjalëkalimi nuk është i njëjtë"),
  });

  return useFormik({
    initialValues: {
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ResetPasswordFormik = ReturnType<typeof useResetPasswordFormik>;
